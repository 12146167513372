<template>
  <div class="container flex-row">

    <op-btn-view class="op-btn" v-if="showView" @click="$emit('view')" :style="btnStyle"/>
    <op-btn-edit class="op-btn" v-if="showEdit" @click="$emit('edit')" :style="btnStyle"/>
    <op-btn-delete class="op-btn" v-if="showDelete" @click="$emit('delete')" :style="btnStyle"/>

  </div>
</template>

<script>
import OpBtnView from "@/components/OpBtnView"
import OpBtnEdit from "@/components/OpBtnEdit"
import OpBtnDelete from "@/components/OpBtnDelete"

export default {
  name: "RecOpBar",
  components: {OpBtnView, OpBtnEdit, OpBtnDelete},

  props: {
    showView: {
      type: Boolean,
      default: true,
    }, // view detail
    showEdit: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },

    btnLrPadding: {
      type: String,
      default: '1px'
    }
  },

  computed: {
    btnStyle() {
      return {
        paddingLeft: this.btnLrPadding,
        paddingRight: this.btnLrPadding
      }
    }
  },

}
</script>

<style lang="scss">
.op-btn {
  margin-right: 2px;
}
</style>