<template>
  <div class="container">
    <div class="operation-bar">
      <button-add @click="showUpsertDialog = 1"></button-add>
    </div>

    <div class="content-block">
      <el-table :data="items">

        <el-table-column align="center" label="会员卡图片">
          <template #default="scope">
            <div class="wrapper flex-center _100-pct-fill">
              <my-image
                  :src="scope.row.imgUrl"
                  width="200px" height="100px"
                  img-border-radius="10px" padding="5px"/>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="名称" align="center"/>
        <el-table-column prop="remark" label="类型" align="center">
          <template #default="scope">
            <!--目前写死-->
            <div>充值卡</div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center"/>

        <el-table-column label="操作" align="center">
          <template #default="scope">
            <div class="flex-center" style="width: 100%; height: 100%">
              <record-op-bar
                  :show-view="false"
                  @edit="openUpdateDialog(scope.row)"
                  @delete="openDeleteDialog(scope.row)"
              />
            </div>
          </template>
        </el-table-column>

      </el-table>
    </div>

    <el-dialog
        v-model.sync="showUpsertDialog"
        :title="(showUpsertDialog === 1 ? '新增' : '修改') + '会员卡模板'"
        width="550px">

      <el-form
          ref="form"
          title="新增会员卡模板"
          :model="upsertReq" :rules="rules"
          label-width="150px">

        <el-form-item label="会员卡图片" prop="imgUrl">
          <el-upload
              accept=".jpg,.jpeg,.png"
              v-if="!upsertReq.imgUrl"
              class="avatar-uploader"
              :action="fileUploadUrl"
              :headers="fileUploadHeaders"
              :show-file-list="false"
              :on-success="onUploadImageSuccess"
          >
            <button-upload/>
          </el-upload>

          <div v-else>
            <deletable-image :src="upsertReq.imgUrl" @deleted="upsertReq.imgUrl = undefined"/>
          </div>

        </el-form-item>

        <el-form-item label="会员卡名称" prop="name">
          <el-input v-model="upsertReq.name" placeholder="请输入会员卡名称" clearable
                    :style="{width: '100%'}">
          </el-input>
        </el-form-item>

        <el-form-item label="类型" prop="type">
          <el-select v-model="upsertReq.type" placeholder="请选择类型" clearable :style="{width: '100%'}">
            <el-option v-for="(item, index) in typeOptions" :key="index" :label="item.label"
                       :value="item.value"
                       :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input v-model="upsertReq.remark" type="textarea" placeholder="请输入备注"
                    :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
        </el-form-item>

        <el-form-item size="large" class="flex-row">
          <button-ok @click="submitForm"></button-ok>
          <button-cancel @click="resetForm" content="重置"/>
        </el-form-item>

      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import DeletableImage from "@/components/DeletableImage"
import ButtonAdd from "@/components/ButtonAdd";
import ButtonOk from "@/components/ButtonOk";
import ButtonCancel from "@/components/ButtonCancel";
import RecordOpBar from "@/components/RecOpBar"
import MyImage from "@/components/MyImage"
import ButtonUpload from "@/components/ButtonUpload.vue"
import userService from "@/ums/services/user-service"

export default {
  name: "MembershipCardTemplate",

  components: {
    ButtonUpload,
    MyImage,
    RecordOpBar,
    ButtonCancel,
    ButtonOk,
    ButtonAdd,
    DeletableImage
  },

  computed: {
    fileUploadHeaders() {
      return {
        Authorization: userService.getToken()
      }
    }
  },

  data() {

    let imgUrlValidator = (rule, value, callback) => {
      if (this.$fuu.strEmpty(value)) {
        callback(new Error('请上传图片'))
      } else {
        callback()
      }
    }

    return {
      fileUploadUrl: process.env.VUE_APP_API_BASE_URL + '/upload',


      items: [],
      showUpsertDialog: 0,

      upsertReq: {
        _id: undefined,
        imgUrl: undefined,
        name: undefined,
        type: "balance",
        remark: undefined,
      },

      rules: {
        name: [{
          required: true,
          message: '请输入会员卡名称',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择类型',
          trigger: 'change'
        }],
        remark: [{
          required: true,
          message: '请输入备注',
          trigger: 'blur'
        }],

        imgUrl: [{
          required: true,
          message: '请上传图片',
          trigger: 'blur'
        }, {
          validator: imgUrlValidator,
          message: '请上传图片',
          trigger: 'blur'
        }],
      },

      typeOptions: [{
        "label": "充值卡",
        "value": "balance"
      }],

    }
  },

  created() {
    this.reload()
  },

  methods: {
    reload() {
      // TODO: check if load templates of just current user

      let query = {'arenaId': this.$store.state.user.arena._id}

      this.$http.get('/clubcard-templates', query).then(resp => {
        if (resp.ok) {
          this.items = resp.data
        }
      })
    },

    onUploadImageSuccess(resp) {
      if (resp.errCode === 0) {
        this.$message.success('图片上传成功')
        this.upsertReq.imgUrl = resp.data
      }
    },

    submitForm() {
      console.log('Submitting form', new Date())
      if (this.$fuu.strEmpty(this.upsertReq.imgUrl)) {
        this.$message.error('请上传图片再试!')
        return
      }

      this.$refs['form'].validate(valid => {
        if (!valid) return

        let req = this.$fuu.copy(this.upsertReq)
        let method = !req._id ? 'POST' : 'PUT'

        this.$http.call(method, '/clubcard-templates', req).then(resp => {
          let ok = this.$fuu.promptingCheckSuccess(resp)
          if (ok) {
            this.resetForm()
            this.showUpsertDialog = 0
            this.reload()
          }
        })

      })
    },

    resetForm() {
      this.$refs['form'].resetFields()
    },

    openUpdateDialog(row) {
      this.$fuu.populateFields(this.upsertReq, row)
      this.showUpsertDialog = 2
    },

    openDeleteDialog(row) {
      this.$fuu.letUserConfirm(
          '只有没有产生会员卡的模板才能删除，确认吗?', '确认删除'
      ).then(confirmed => {
        if (!confirmed) return

        let url = '/clubcard-templates/' + row._id
        this.$http.delete(url).then(resp => {
          let ok = this.$fuu.promptingCheckSuccess(resp)
          if (ok) this.reload()
        })
      })
    }

  }
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables";

.op-box {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 50px;
  padding-left: 5px;

  .op {
    padding-left: 5px;
    padding-right: 5px;
  }


  .op:nth-child(1) {
    color: orange;
  }


  .op:nth-child(2) {
    color: red;
  }

}

.img-wrapper {
  width: 60px;
  height: 60px;
  padding: 2px;
  border: 1px solid grey;

  .img {
    border-radius: 5px;
  }
}
</style>