<template>
  <div class="container">
    <div class="sub-menu-block">
      <div class="parent flex-center">{{ menu.name }}</div> <!--The title-->
      <div v-for="subMenu in menu.children" :key="subMenu.id"
           style="cursor: pointer"
           class="child flex-center" @click="onSubMenuClick(subMenu)">
        {{ subMenu.name }}
      </div>
    </div>
  </div>
</template>

<script>
import menuService from '@/ums/services/menu-service'

export default {
  name: "MenuBarSubMenuBlock",
  props: ['menu'],
  methods: {
    onSubMenuClick(menu) {
      menuService.openTab(menu)
    }
  }
}
</script>

<style scoped lang="scss">

.flex-center {
  display: flex;
  align-items: center;
}

.container {
  width: 100%;

  .sub-menu-block {
    width: 100%;
    padding-top: calc(80px - 64px);

    .parent {
      height: 32px;
      padding-left: 16px;
      background-color: #ffffff;
      color: #888c94;
      font-size: 12px;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    .child {
      height: 32px;
      padding-left: 32px;
      font-size: 12px;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      background-color: #ffffff;
    }

    .child:hover {
      background-color: #f0f5fe; // TOOD: use variable
    }
  }
}
</style>
