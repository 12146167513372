<template>
    <div class="button-container">
        <el-button :size="size">
            <i class="iconfont icon-cancel-1" style="margin-right: 2px"></i>
            {{ content }}
        </el-button>
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: String,
            default: '取消'
        },

        size: {
            type: String,
            default: 'default'
        }

    }
}
</script>

<style scoped>
.button-container {
    padding-right: 5px;
}
</style>