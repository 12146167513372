<template>
  <div class="container">
    <div class="query-bar">
      <div class="wrapper" style="width: 250px">
        <el-input v-model="query.q" placeholder="用户昵称, 手机号"/>
      </div>
      <button-sch @click="reload"/>
    </div>

    <div class="op-bar flex-row">
      <button-add @click="showCreator = true" content="添加会员"></button-add>

      <el-button @click="upload.open = true">
        <i class="iconfont icon-fileplus-fill" style="margin-right: 2px"/>
        批量导入
      </el-button>

    </div>

    <el-table :data="items" @expand-change="handleExpandChange" row-key="_id">
      <el-table-column type="expand">
        <template #default="scope">
          <div class="clubcards-table">
            <el-table :data="scope.row.clubcards" :show-header="false">
              <el-table-column align="center">
                <template #default="scope">
                  <div class="img-wrapper-clubcard">
                    <el-image class="img" :src="scope.row.imgUrl"
                              style="width: 100%; height: 100%"></el-image>
                  </div>
                </template>
              </el-table-column>

              <el-table-column prop="name" label="名称" align="center"/>
              <el-table-column prop="balance" label="余额" align="center"/>

              <el-table-column label="操作" align="center">
                <template #default="scope2">
                  <div class="card-op-box flex-center">
                    <el-tooltip
                        effect="dark"
                        content="修改余额"
                        placement="top">
                      <div class="op edit op-btn-wrapper">
                        <i class="iconfont icon-chongzhi"
                           @click=" openChangeBalanceDialog(scope.row, scope2.row)"/>
                      </div>
                    </el-tooltip>

                    <el-tooltip
                        effect="dark"
                        content="充值"
                        placement="top">
                      <div class="op recharge op-btn-wrapper">
                        <i class="iconfont icon-jinbi"
                           @click="openRechargeDialog(scope.row, scope2.row)"/>
                      </div>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>

            </el-table>

          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" width="100px">
        <template #default="scope">
          <div class="img-wrapper">
            <el-image class="img" :src="scope.row.portrait" style="width: 100%; height: 100%"
                      v-if="scope.row.portrait"></el-image>
            <div class="surname flex-center the-title" v-else
                 :style="{backgroundColor: randomColor(scope.row.nickname), color: 'white'}">
              {{ scope.row.nickname.substring(0, 1) }}
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="nickname" label="昵称" align="center"/>
      <el-table-column prop="phone" label="手机号" align="center"/>
      <el-table-column prop="wechat" label="微信号" align="center"/>
      <el-table-column prop="rechargeTimes" label="充值次数" align="center"/>
      <el-table-column prop="totalRechargeAmount" label="累计充值金额" align="center"/>
      <el-table-column prop="level" label="等级" align="center"/>
      <el-table-column prop="remark" label="备注" align="center"/>

      <el-table-column label="操作" align="center">
        <template #default="scope">
          <div class="flex-center">
            <button-edit @click="openUpdateMemberDialog(scope.row)" size="small"/>
          </div>
          <!--                    <div class="op-box flex-center" style="display: flex; flex-direction: row">

                              </div>-->
        </template>
      </el-table-column>

    </el-table>

    <div class="pagination-wrapper" style="display: flex; justify-content: flex-end; margin-top: 10px">
      <el-pagination
          v-model:current-page="query.pageNum"
          v-model:page-size="query.pageSize"
          :page-sizes="[1, 10, 20, 50]" background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalRecs"
          @size-change="reload"
          @current-change="reload"
      />
    </div>

    <el-dialog v-model.sync="showCreator" title="添加会员" width="500px">
      <member-creator @success="onCreateOneSuccess"/>
    </el-dialog>

    <el-dialog v-model.sync="showChangeBalanceDialog" title="修改会员卡余额" width="600px">
      <el-form ref="changeBalanceForm" :model="changeBalanceReq" :rules="changeBalanceRules"
               label-width="150px">
        <el-form-item label="金额" prop="balance">
          <el-input-number style="width: 250px" v-model="changeBalanceReq.balance" placeholder="金额"
                           :step='50'
                           :precision='2'>
          </el-input-number>
        </el-form-item>


        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="changeBalanceReq.remark" placeholder="请输入备注"
                    :autosize="{minRows: 2, maxRows: 4}">
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitChangeBalanceReq">提交</el-button>
          <el-button @click="this.$refs['changeBalanceReq'].resetFields()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-model.sync="showRechargeDialog" title="充值" width="500px">
      <el-form ref="rechargeForm" :model="rechargeReq" :rules="rechargeRules"
               label-width="150px">

        <el-form-item label="金额" prop="balance">
          <el-input-number
              style="width: 250px" v-model="rechargeReq.amount" placeholder="金额" :step='50'
              :precision="0" :min="10">
          </el-input-number>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="rechargeReq.remark" placeholder="请输入备注"
                    :autosize="{minRows: 2, maxRows: 4}">
          </el-input>
        </el-form-item>

        <el-form-item size="small">
          <div class="buttons flex-row">
            <button-ok @click="submitRechargeReq"/>
            <button-cancel @click="$refs['rechargeForm'].resetFields()" content="重置"/>
          </div>
        </el-form-item>

      </el-form>
    </el-dialog>

    <!-- 用户导入对话框 -->
    <el-dialog :title="upload.title" v-model="upload.open" width="400px" append-to-body>
      <el-upload
          ref="upload"
          :limit="1"
          accept=".xlsx, .xls"
          :headers="upload.headers"
          :action="upload.url + '?updateSupport=' + upload.updateSupport"
          :disabled="upload.isUploading"
          :on-progress="handleFileUploadProgress"
          :on-success="handleFileSuccess"
          :auto-upload="false"
          drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip text-center" slot="tip">
          <!--          <div class="el-upload__tip" slot="tip">
                      <el-checkbox v-model="upload.updateSupport"/>
                      是否更新已经存在的用户数据
                    </div>-->
          <span>仅允许导入xls、xlsx格式文件。</span>
          <!--          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;"
                             @click="importTemplate">下载模板
                    </el-link>-->
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">
          <i class="iconfont icon-correct-3" style="margin-right: 2px; font-size: 10px"></i>
          确 定
        </el-button>
        <el-button @click="upload.open = false">
          <i class="iconfont icon-cancel-1" style="margin-right: 2px; font-size: 10px"></i>
          取 消
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改会员" width="450px" v-model="showUpdateMemberDialog">
      <el-form ref="updateMemberForm" :model="updateMemberReq" :rules="rules" label-width="119px">
        <el-form-item label="会员等级" prop="level">
          <el-select v-model="updateMemberReq.level" placeholder="请选择会员等级" clearable
                     :style="{width: '100%'}">
            <el-option v-for="(item, index) in levelOptions" :key="index" :label="item.label"
                       :value="item.value" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="备注">
          <el-input v-model="updateMemberReq.remark" type="textarea" placeholder="请输入文本行多备注"
                    :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
        </el-form-item>


        <el-form-item class="flex-row">
          <button-ok content="提交" @click.prevent="submitUpdateMemberReq"></button-ok>
          <button-cancel @click=""></button-cancel>
        </el-form-item>

      </el-form>

    </el-dialog>

  </div>
</template>

<script>
import http from "@/utils/request"
import MemberCreator from "@/operation/components/MemberCreator"
import ButtonAdd from "@/components/ButtonAdd"
import userService from '@/ums/services/user-service'
import ButtonOk from "@/components/ButtonOk"
import ButtonCancel from "@/components/ButtonCancel"
import ButtonSch from "@/components/ButtonSch.vue"
import RecOpBar from "@/components/RecOpBar.vue"
import store from "@/store"
import ButtonEdit from "@/components/ButtonEdit.vue";

export default {
  name: "Member",
  components: {ButtonEdit, RecOpBar, ButtonSch, ButtonCancel, ButtonOk, ButtonAdd, MemberCreator},
  created() {
    this.reload()
  },

  data() {
    return {

      showUpdateMemberDialog: false,

      showCreator: false,

      fileList: [],
      arenaId: undefined,

      query: {
        q: undefined,
        pageNum: 1,
        pageSize: 10,
      },

      items: [],
      totalRecs: 0,
      totalPages: 0,

      showChangeBalanceDialog: false,
      showRechargeDialog: false,


      curMember: undefined,

      changeBalanceReq: {
        clubcardId: undefined,
        balance: undefined,
      },

      changeBalanceRules: {
        balance: [{
          required: true,
          message: '金额不能为空',
          trigger: 'blur'
        }],

        remark: [{
          required: true,
          message: '备注不能为空',
          trigger: 'blur'
        }],

      },

      rechargeReq: {
        clubcardId: undefined,
        amount: 100,
        remark: undefined,
      },

      rechargeRules: {
        amount: [{
          required: true,
          message: '金额不能为空',
          trigger: 'blur'
        }],

        remark: [{
          required: true,
          message: '备注不能为空',
          trigger: 'blur'
        }],

      },

      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: "批量导入会员",
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: {Authorization: userService.getToken()},
        url: process.env.VUE_APP_API_BASE_URL + "/members/upload",
      },


      updateMemberReq: {
        level: "普通会员",
        remark: undefined,
      },
      rules: {
        field102: [{
          required: true,
          message: '请选择会员等级',
          trigger: 'change'
        }],

        remark: [{
          required: true,
          message: '请输入文本行多备注',
          trigger: 'blur'
        }],
      },

      levelOptions: [{
        "label": "普通会员",
        "value": "普通会员"
      }, {
        "label": "高级会员",
        "value": "高级会员"
      }],
    }

  },

  methods: {

    randomColor(nickname) {
      return this.$fuu.randomDarkColor(nickname)
    },

    reload() {
      this.arenaId = store.getters["user/curUserArena"]._id
      let query = this.$fuu.copy(this.query)
      query.arenaId = this.arenaId // {arenaId: '', q: }

      http.get(`/members`, query).then(resp => {
        let page = resp.data
        this.items = page.items

        this.totalRecs = page.totalRecs
        this.totalPages = page.totalPages
      })
    },

    onCreateOneSuccess() {
      this.showCreator = false
      this.reload()
    },

    handleExpandChange(row) {
      this.reloadMemberClubCards(row, false)
    },

    reloadMemberClubCards(member, reloadEvenExisting = false) {

      console.log('Reloading member cards.')

      if (!reloadEvenExisting) {
        if (member.clubcards != null) {
          console.log('Not reload even exist, and member already got clubcards, return.')
          return
        }
      }

      console.log('Find clubcards for user: ', member._id)
      let q = {'userId': member.userId}
      this.$http.get('/clubcards', q).then(resp => {
        if (resp.ok) {
          console.log('Got clubcards, size: ', resp.data.length)
          member.clubcards = resp.data
        }
      })

    },

    openChangeBalanceDialog(member, clubcard) {
      this.changeBalanceReq.clubcardId = clubcard._id
      this.changeBalanceReq.balance = clubcard.balance
      this.showChangeBalanceDialog = true

      this.curMember = member
    },

    submitChangeBalanceReq() {
      this.$refs['changeBalanceForm'].validate(valid => {
        if (!valid) return

        this.$http.put('/clubcards/modify-balance', this.changeBalanceReq).then(resp => {
          let ok = this.$fuu.promptingCheckSuccess(resp)
          if (ok) {
            this.reloadMemberClubCards(this.curMember, true)
            this.showChangeBalanceDialog = false
            this.curMember = {...this.curMember, ...this.updateMemberReq}
          }
        })
      })
    },

    openRechargeDialog(member, clubcard) {
      this.rechargeReq.remark = undefined
      this.rechargeReq.clubcardId = clubcard._id
      this.curMember = member

      this.showRechargeDialog = true
    },

    submitRechargeReq() {
      this.$refs['rechargeForm'].validate(valid => {
        if (!valid) return

        this.$http.put('/clubcards/recharge', this.rechargeReq).then(resp => {
          let ok = this.$fuu.promptingCheckSuccess(resp)
          if (ok) {
            this.reloadMemberClubCards(this.curMember, true)
            this.showRechargeDialog = false

            // Modify member record to show these two fields.
            this.curMember.rechargeTimes += 1
            this.curMember.totalRechargeAmount += this.rechargeReq.amount
          }
        })
      })
    },

    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },

    handleFileSuccess(resp, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();

      let msg = resp.data.msg

      this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + msg + "</div>", "导入结果", {dangerouslyUseHTMLString: true});
    },

    submitFileForm() {
      this.$refs.upload.submit();
    },

    openUpdateMemberDialog(member) {
      this.curMember = member

      this.updateMemberReq = {
        userId: member.userId,
        arenaId: member.arenaId,
        remark: member.remark,
        level: member.level
      }

      this.showUpdateMemberDialog = true
    },

    submitUpdateMemberReq() {

      this.$http.put('/members', this.updateMemberReq).then(resp => {
        let ok = this.$fuu.promptingCheckSuccess(resp)
        if (ok) {
          for (let k of ['remark', 'level']) {
            this.curMember[k] = this.updateMemberReq[k]
          }
          window.CM = this.curMember
        }
        this.showUpdateMemberDialog = false
      }).catch(err => {
        this.$message.error('出错啦!')
        this.showUpdateMemberDialog = false
      })
    }

  }

}
</script>

<style scoped lang="scss">
$opBarHeight: 50px;

.query-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;

  height: $opBarHeight;
  padding-left: 5px;

  .wrapper {
    margin-right: 10px;
  }

}

.op-bar {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: $opBarHeight;
  padding-left: 5px;

}

.op-bar > * {
  margin-right: 10px;
}

.img-wrapper {
  width: 3rem;
  height: 3rem;
  padding: 2px;

  .img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .surname {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

}

.clubcards-table {
  width: 100%;
  padding-left: 5rem;
  padding-right: 5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  .img-wrapper-clubcard {
    width: calc(10rem * 0.8);
    height: calc(5.5rem * 0.8);

    .img {
      height: 100%;
      width: 100%;
      border-radius: 8px;
    }
  }

  .card-op-box {
    .op {
      .iconfont {
        margin-top: 2px;
        font-size: 15px;
      }

      margin-left: 2px;
      margin-right: 2px;
    }

    .op.edit {
      background-color: red;
    }

    .op.recharge {
      background-color: orange;
    }
  }
}


</style>