import {createRouter, createWebHashHistory} from 'vue-router'
import Layout2 from "@/layouts/layout-2/Layout2"
import Menu from "@/ums/views/Menu"
import Role from "@/ums/views/Role"
import User from '@/ums/views/User'
import ArenaDetail from "@/ams/views/ArenaDetail"
import Region from "@/ams/views/Region"
import Home from "@/ums/views/Home"
import RegionDetail from "@/ams/views/RegionDetail"
import Member from "@/operation/views/Member"
import Order from "@/operation/views/Order"
import Login from "@/ums/views/Login"
import ClubcardTemplate from "@/operation/views/ClubcardTemplate"
import Temp3 from "@/Temp3"
import Temp4 from "@/Temp4"
import Temp5 from '@/Temp5'
import Temp6 from "@/Temp6"

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '',
            component: Layout2,
            children: [
                {path: '', component: Home}
            ]
        },

        {
            path: '',
            component: Layout2,
            children: [
                {path: '/ums/menus', component: Menu}
            ]
        },
        {
            path: '',
            component: Layout2,
            children: [
                {path: '/ums/roles', component: Role}
            ]
        },
        {
            path: '',
            component: Layout2,
            children: [
                {path: '/ums/users', component: User}
            ]
        },
        {
            path: '',
            component: Layout2,
            children: [
                {path: '/ams/arena-detail', component: ArenaDetail}
            ]
        },

        {
            path: '',
            component: Layout2,
            children: [
                {path: '/ams/region', component: Region}
            ]
        },

        {
            path: '',
            component: Layout2,
            children: [
                {path: '/ams/region-detail', component: RegionDetail}
            ]
        },

        {
            path: '',
            component: Layout2,
            children: [
                {path: '/operation/member', component: Member}
            ]
        },

        {
            path: '',
            component: Layout2,
            children: [
                {path: '/operation/order', component: Order}
            ]
        },

        {
            path: '',
            component: Layout2,
            children: [
                {path: '/operation/clubcard-template', component: ClubcardTemplate}
            ]
        },

        {
            path: '',
            component: Layout2,
            children: [
                {path: '/tmp2', component: import("@/Temp2")}
            ]
        },

        {
            path: '',
            component: Layout2,
            children: [
                {path: '/tmp', component: import("@/Temp")}
            ]
        },

        {
            path: '',
            component: Layout2,
            children: [
                {path: '/tmp3', component: import("@/Temp3")}
            ]
        },

        {
            path: '',
            component: Layout2,
            children: [
                {path: '/tmp4', component: import("@/components/AvatarUpload")}
            ]
        },

        {
            path: '',
            component: Layout2,
            children: [
                {path: '/tmp5', component: import("@/ams/views/ArenaDetailEditor")}
            ]
        },

        {
            path: '/login',
            component: Login
        },

        {
            path: '/T3',
            component: Temp3
        },

        {
            path: '/T4',
            component: Temp4
        },

        {
            path: '/T5',
            component: Temp5,
        },

        {
            path: '/T6',
            component: Temp6,
        },


    ]
})

export default router
