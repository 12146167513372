<template>
  <div class="container">
    <div class="left">
      <!--Left padding, blank-->
    </div>

    <div class="content">
      <div class="left">
        <el-carousel :interval="3000" arrow="always" class="carousel" loop height="650px">
          <!--TODO: configure some where-->
          <el-carousel-item class="carousel-item" v-for="(swiper, idx) in swipers" :key="idx">
            <el-image class="swiper-image" :src="swiper.imgUrl" fit="fill"/>
          </el-carousel-item>

        </el-carousel>
      </div>

      <div class="right">
        <div class="tabs">
          <div class="tab active">账号登录</div>
        </div>

        <div class="logo">
          <el-image
              src="https://my-southchina-open-bucket.oss-cn-guangzhou.aliyuncs.com/63d617b5396f36615cd9683d.png"></el-image>
        </div>

        <div class="login-box" style="width: 100%;">
          <el-form ref="form" :model="loginReq" :rules="rules">
            <el-form-item label-width="0" prop="username">
              <el-input
                  v-model="loginReq.username"
                  placeholder="请输入账号" :maxlength="11"
                  show-word-limit clearable
                  prefix-icon='el-icon-mobile' :style="{width: '100%'}"></el-input>
            </el-form-item>

            <el-form-item label-width="0" prop="password">
              <el-input
                  type="password"
                  v-model="loginReq.password"
                  placeholder="请输入密码" clearable
                  :style="{width: '100%'}">
              </el-input>
            </el-form-item>

            <div>
              <el-checkbox v-model="loginReq.rememberMe"/>
              <span style="font-size: 10px; color: grey; margin-left: 10px">15天免登录</span>
            </div>

            <div size="large" style="margin-top: 20px">
              <el-button type="primary" @click="submitForm" style="width: 100%; height: 45px;">
                立即登录
              </el-button>
            </div>

          </el-form>

        </div>
      </div>


    </div>
  </div>
</template>

<script>
import userService from '../services/user-service'
import md5 from 'md5'

export default {
  name: "Login",

  mounted() {
    let redirect = this.$router.currentRoute?.query?.redirect
    this.redirectTo = redirect || '/'

    if (userService.loggedIn()) {
      console.log('Already logged in, redirecting to: ' + this.redirectTo)
    }
  },

  data() {
    return {
      swipers: [
        {
          imgUrl: 'https://my-southchina-open-bucket.oss-cn-guangzhou.aliyuncs.com/63bbe1bf396f36ddb8e23473.png'
        },
        {
          imgUrl: 'https://my-southchina-open-bucket.oss-cn-guangzhou.aliyuncs.com/63d60fa0396f36523c2791f6.png'
        }
      ],

      loginReq: {
        username: undefined,
        password: undefined,
        rememberMe: true
      },

      rules: {
        username: [{
          required: true,
          message: '请输入账号',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }]
      },

      redirectTo: '/'
    }
  },

  methods: {
    submitForm() {
      this.$refs.form.validate(valid => {
        if (!valid) return

        let params = this.$fuu.copy(this.loginReq)
        params.phone = params.username
        delete params['username']

        params.password = md5(params.password)

        userService.login(params).then(resp => {
          if (resp.ok) {
            console.log('Login success, redirecting to:', this.redirectTo)
            this.$router.push(this.redirectTo)
          } else {
            this.$message.error('用户名或密码错误')
          }
        }).catch(err => {
          console.log('Login failure!', err)
          this.$message.error('出错啦, 请稍后再试!')
        })

      })
    },
  }

}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

$loginBoxWidth: 400px;
$contentHeight: 650px;

$contentMinWidth: 1024px;
$contentWidth: max(80vw, #{$contentMinWidth});
$leftPaddingWidth: max(0px, 0.5 * (100vw - #{$contentWidth}));

.container {
  display: flex;
  flex-direction: row;
  width: 100vw;

  .left {
    width: $leftPaddingWidth;
  }

  .content {
    margin-top: 10px;
    width: $contentWidth;
    height: $contentHeight;
    display: flex;
    flex-direction: row;

    .left {
      width: calc(#{$contentWidth} - #{$loginBoxWidth});
      height: 100%;

      .carousel {
        height: $contentHeight;
      }

      .carousel-item {
        width: 100%;
        height: 100%;

        .swiper-image {
          height: 100%;
          width: 100%;
          background-color: red;
        }
      }

    }

    .right {

      width: $loginBoxWidth;
      height: 100%;
      background-color: white;

      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      //justify-content: center;

      .tabs {
        .tab {
          display: flex;
          justify-content: center;
          align-items: center;

          color: $themeColor;
          height: 40px;
        }

        .tab.active {
          border-bottom: 3px solid $themeColor;
        }
      }

      .logo {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
