<template>
    <div class="container">

        <div class="operation-bar">
            <button-add @click="showCreatorDialog = true"/>
        </div>

        <el-table :data="regions" :header-cell-style="{'text-align': 'center'}">
            <el-table-column label="运动" align="center" width="120rem">
                <template #default="scope">
                    <div class="wrapper flex-center">
                        <div class="img-box flex-center" v-if="scope.row.sport">
                            <el-image class="img" :src="scope.row.sport.imgUrl"
                                      style="width: 100%; height: 100%"></el-image>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="区域名称" align="center">
                <template #default="scope">
                    <div class="link" @click="$router.push('/ams/region-detail?_id=' + scope.row._id)">
                        {{ scope.row.name }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="营业时间" align="center">
                <template #default="scope">
                    <el-tooltip :content="scope.row.openFromToTip" placement="top">
                        {{ scope.row.openFromToStr }}
                    </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column label="提前预定天数" prop="cReserveDaysInAdvance" align="center"/>
            <el-table-column label="取消订单天数" prop="cancelOrderWithinDays" align="center"/>

            <el-table-column label="单价" prop="pricePerHour" align="center"/>

            <el-table-column label="场地数量" align="center">
                <template #default="scope">
                    <div style="cursor: pointer; color: #4897da" @click="toRegionCourtPage(scope.row)">
                        {{ scope.row.courtNum || '--' }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="备注" align="center">
                <template #default="scope">
                    {{ scope.row.remark }}
                </template>
            </el-table-column>

            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <div class="flex-center">
                        <rec-op-bar
                                @view="$router.push('/ams/region-detail?_id=' + scope.row._id)"
                                @edit="openUpdateDialog(scope.row)"
                                @delete="openDeleteDialog(scope.row)"
                        />
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog v-model="showCreatorDialog" title="新增区域" width="820px">
            <region-creator @success="createRegionSuccess"></region-creator>
        </el-dialog>

        <el-dialog v-model="showUpdateDialog" width="600px" title="修改区域">
            <el-form ref="form" :model="updateReq" :rules="rules" size="medium" label-width="150px">

                <div class="row">
                    <el-form-item label="区域名称" prop="name">
                        <el-input v-model="updateReq.name" placeholder="请输入区域名称" clearable
                                  :style="{width: '50%'}">
                        </el-input>
                    </el-form-item>
                </div>

                <el-form-item label="运动" prop="sportCode">
                    <el-select v-model="updateReq.sportCode" placeholder="请选择运动" clearable :style="{width: '30%'}">
                        <el-option v-for="(item, index) in sports" :key="index" :label="item.name"
                                   :value="item.code" :disabled="item.disabled"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="营业时间" prop="openFromTo">
                    <el-time-select v-model="updateReq.openFromTo[0]" :style="{width: '30%'}"
                                    start="06:00" end="23:00" step="01:00"
                                    start-placeholder="开始时间" end-placeholder="结束时间" range-separator="至"
                                    clearable></el-time-select>

                    <section style="padding-left: 10px; padding-right: 10px;">至</section>

                    <el-tooltip :content="opEndTimeTip">
                        <el-time-select v-model="updateReq.openFromTo[1]" :style="{width: '30%'}"
                                        start="06:00" end="23:00" step="01:00"
                                        start-placeholder="开始时间" end-placeholder="结束时间" range-separator="至"
                                        clearable></el-time-select>
                    </el-tooltip>

                </el-form-item>

                <el-form-item label="提前预定天数" prop="cReserveDaysInAdvance">
                    <el-input-number
                            v-model="updateReq.cReserveDaysInAdvance" placeholder="提前预定天数" :step='1' :min='3'
                            :max='14'>
                    </el-input-number>
                </el-form-item>

                <el-form-item label="可取消订单天数" prop="cancelOrderWithinDays">
                    <el-input-number
                            v-model="updateReq.cancelOrderWithinDays" placeholder="可取消订单天数"
                            :min="0" :max="7" :step="1"
                    />
                </el-form-item>

                <el-form-item label="单价" prop="pricePerHour">
                    <el-input-number v-model="updateReq.pricePerHour" placeholder="单价" :step='1'
                                     controls-position=right></el-input-number>
                </el-form-item>


                <el-form-item label="备注" prop="remark">
                    <el-input v-model="updateReq.remark" type="textarea" placeholder="请输入备注"
                              :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
                </el-form-item>

                <el-form-item size="medium">
                    <el-button type="primary" @click="submitForm">提交</el-button>
                    <el-button @click="resetForm">重置</el-button>
                </el-form-item>

            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import regionService from '../services/region-service'
import RegionCreator from "@/ams/components/RegionCreator"
import RecordOpBar from "@/components/RecOpBar.vue"
import RecOpBar from "@/components/RecOpBar.vue"
import ButtonAdd from "@/components/ButtonAdd.vue"

export default {

    name: "Region",

    components: {
        ButtonAdd,
        RecOpBar,
        RecordOpBar,
        RegionCreator,
    },

    created() {
        this.$http.get('/sports').then(resp => {
            this.sports = resp.data
        })
    },

    mounted() {
        this.reload()
    },

    data() {
        return {
            sports: [],
            regions: [],

            showCreatorDialog: false,
            showUpdateDialog: false,

            updateReq: {
                _id: undefined,
                'arenaId': undefined,
                'name': undefined,
                'sportCode': undefined,
                'openFromTo': undefined,
                'cReserveDaysInAdvance': undefined,
                'pricePerHour': undefined,
                'remark': undefined,
            },

            rules: {
                name: [{
                    required: true,
                    message: '请输入区域名称',
                    trigger: 'blur'
                }],

                sportCode: [{
                    required: true,
                    message: '请选择运动',
                    trigger: 'change'
                }],

                openFromTo: [{
                    validator: (rule, value, callback) => {
                        if (value.length < 2) callback(new Error('请选择营业时间'))

                        let t1 = value[0], t2 = value[1] // 09:00, 23:00
                        if (t1 >= t2) {
                            callback(new Error('开始时间必须小于营业时间'))
                        }

                        callback()
                    },
                    trigger: 'blur'
                }],

                cReserveDaysInAdvance: [{
                    required: true,
                    message: '提前预定天数',
                    trigger: 'blur'
                }],

                cancelOrderWithinDays: [{
                    required: true,
                    message: '请输入可取消订单天数',
                    trigger: 'blur'
                }],

                pricePerHour: [{
                    required: true,
                    message: '单价',
                    trigger: 'blur'
                }]

            }
        }

    },

    computed: {
        opEndTimeTip() {
            let val = this.updateReq.openFromTo[1] // 23:00
            let hour = parseInt(val.substring(0, 2))
            let tip = `营业至${hour}点, 用户最晚可以定${hour - 1}点场次`
            return tip
        }
    },

    methods: {
        reload() {
            regionService.fetchRegionsOfCurUser().then(resp => {
                let page = resp.data
                this.regions = page.items

                function toHHMM(n) { // 9 -> 09:00
                    let s = `0${n}`
                    return s.substr(s.length - 2) + ":00"
                }

                this.regions.forEach(r => {
                    let ab = r.openFromTo // [9, 22]
                    r.openFromToStr = toHHMM(ab[0]) + ' 至 ' + toHHMM(ab[1] + 1)
                    r.openFromToTip = `营业至${ab[1] + 1}点, 用户最晚可定${ab[1]}点场次`
                })
            })
        },

        createRegionSuccess() {
            this.showCreatorDialog = false
            this.reload()
        },

        openUpdateDialog(row) {
            this.resetForm(row)
            this.showUpdateDialog = true
        },

        resetForm(row) {
            for (let k in this.updateReq) {
                this.updateReq[k] = row[k]
            }

            // mother fuck js, fuck!!!
            let [a, b] = this.updateReq.openFromTo

            function toHHMM(x) {
                let s = `${x}:00`
                if (x < 10) {
                    s = '0' + s
                }
                return s
            }

            let s1 = toHHMM(parseInt(a))
            let s2 = toHHMM(parseInt(b + 1))

            this.updateReq.openFromTo = [s1, s2]
        },

        submitForm() {
            let req = this.$fuu.copy(this.updateReq)
            let [a, b] = req.openFromTo

            function toHour(s) { // s: 23:00
                return parseInt(s.substring(0, 2))
            }

            a = toHour(a)
            b = toHour(b) - 1
            req.openFromTo = [a, b]

            this.$http.put('/ams/regions/' + this.updateReq._id, req).then(resp => {

                let ok = this.$fuu.promptingCheckSuccess(resp)
                if (ok) {
                    this.showUpdateDialog = false
                    this.reload()
                }

            })
        },

        openDeleteDialog(row) {
            let prompt = '只有没有场地的区域才能被删除, 确认吗?'
            this.$fuu.letUserConfirm(prompt).then(yes => {
                if (!yes) return
                let url = '/ams/regions/' + row._id
                this.$http.delete(url).then(resp => {
                    let ok = this.$fuu.promptingCheckSuccess(resp)
                    if (ok) this.reload()
                })
            })
        },

        toRegionCourtPage(row) {
            console.log('To region courts page.')
            let url = `/ams/region-detail?_id=${row._id}&tab=courts`
            this.$router.push(url).then(_ => null)
        }

    },

}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables";

$imgSize: max(3rem, calc(4 * #{$stdFontSize}));
.img-box {
  padding: 5%;
  width: $imgSize;
  height: $imgSize;
}

.op-box {
  display: flex;
  flex-direction: row;

  .op {
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  .iconfont {
    font-size: 20px;
  }

  .view-detail {
    .iconfont {
      color: $themeColor;
    }
  }

  .edit {
    .iconfont {
      color: orange;
    }
  }

  .delete {
    .iconfont {
      color: red;
    }
  }
}
</style>