<template>
    <div class="container">
        <el-tooltip
                effect="dark"
                content="修改"
                placement="top">
            <div class="flex-center op-btn-wrapper">
                <i class="iconfont icon-xiugai1" style="font-size: 8px"/>
            </div>
        </el-tooltip>
    </div>
</template>

<style lang="scss" scoped>
@import "my-btn-style";

.op-btn-wrapper {
  background-color: orange;
}
</style>