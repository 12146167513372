<template>
    <div class="top-bar-tabs-wrapper flex-row">
        <draggable v-model="selectedTabs" :move="onMove" class="flex-row" item-key="_id">
            <template #item="{element}">
                <top-bar-tab :tab="element"/>
            </template>
        </draggable>
    </div>
</template>

<script setup>
import draggable from "vuedraggable"
import store from '@/store'
import TopBarTab from "@/layouts/layout-2/components/TopBarTab.vue"
import {computed, getCurrentInstance} from "vue"


window.T = getCurrentInstance()
const selectedTabs = computed({
    get: _ => store.state.menu.selectedTabs,
    set: v => store.commit('menu/setSelectedTabs', v)
})

function onMove(drag) {
    if (drag.draggedContext.futureIndex === 0) return false

    let curTabIdx = drag.draggedContext.index
    console.log('Trying to move tab: ', curTabIdx)

    // let tab = selectedTabs.value[curTabIdx]
    // if(tab._id !== store.state.menu.activeTab._id) return false // can only drag active tab

    return curTabIdx !== 0

}

</script>