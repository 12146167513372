<template>
    <div class="container">
        <el-form ref="form" :model="upsertReq" :rules="rules" size="medium" label-width="150px">
            <el-form-item label="手机号" prop="phone">
                <el-input v-model="upsertReq.phone" placeholder="请输入手机号" clearable :style="{width: '100%'}">
                </el-input>
            </el-form-item>
            <el-form-item label="昵称" prop="nickname">
                <el-input v-model="upsertReq.nickname" placeholder="请输入昵称" clearable :style="{width: '100%'}">
                </el-input>
            </el-form-item>
            <el-form-item label="微信号" prop="wechat">
                <el-input v-model="upsertReq.wechat" placeholder="请输入微信号" clearable :style="{width: '100%'}">
                </el-input>
            </el-form-item>

            <el-form-item label="初始密码" prop="password">
                <el-input
                        v-model="upsertReq.password"
                        type="password"
                        placeholder="请输入初始密码"
                        show-password
                />
            </el-form-item>

            <el-form-item label="重复初始密码" prop="password2">
                <el-input
                        v-model="upsertReq.password2"
                        type="password"
                        placeholder="重复初始密码"
                        show-password
                />
            </el-form-item>

            <el-form-item label="会员等级" prop="level">
                <el-select v-model="upsertReq.level" placeholder="请选择会员等级" clearable :style="{width: '100%'}">
                    <el-option v-for="(item, index) in levelOptions" :key="index" :label="item.label"
                               :value="item.value" :disabled="item.disabled"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="添加会员卡" prop="clubCards">
                <el-select
                        class="top-n-select"
                        v-model="upsertReq.clubcardId" placeholder="请选择" :style="{width: '100%'}" size="mini">
                    <el-option v-for="(item, idx) in clubcardTemplates" :key="idx" :label="item.name"
                               :value="item._id"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="会员卡金额" prop="clubCardBalance">
                <el-input-number
                        v-model="upsertReq.clubcardBalance"
                        :min="100" :max="10000"
                        placeholder="计数器" :step='1' :precision="0"></el-input-number>
            </el-form-item>

            <el-form-item label="备注" prop="remark">
                <el-input v-model="upsertReq.remark" type="textarea" placeholder="请输入备注"
                          :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
            </el-form-item>

            <div slot="footer">
                <div class="flex-center _100-pct-fill">
                    <button-ok @click="submitForm"/>
                    <button-cancel content="重置" @click="resetForm"/>
                </div>
            </div>
        </el-form>

    </div>
</template>

<script>
import ButtonOk from "@/components/ButtonOk"
import ButtonCancel from "@/components/ButtonCancel"
import md5 from "md5";

export default {
    components: {ButtonCancel, ButtonOk},

    created() {
        // find clubcard templates of current user.
        this.$http.get('/clubcard-templates').then(resp => {
            this.clubcardTemplates = resp.data
            this.upsertReq.clubcardId = resp.data[0]._id
        })
    },

    data() {

        const validatePasswordConsistent = (rule, value, callback) => {
            let pwd2 = this.upsertReq.password
            if (value !== pwd2) {
                callback(new Error('两次输入的密码不一致'))
            } else {
                callback()
            }
        }

        return {
            flag: true,

            clubcardTemplates: [],

            upsertReq: { // https://bigloser.atlassian.net/wiki/spaces/LosersGrou1/pages/1638408/B#[inlineExtension]添加会员
                phone: undefined,
                nickname: undefined,
                wechat: undefined,
                password: undefined,
                password2: undefined,
                clubcardId: undefined,
                clubcardBalance: 500,

                level: "普通会员",
                remark: undefined,
            },

            rules: {
                phone: [{
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur'
                }],

                nickname: [{
                    required: true,
                    message: '请输入昵称',
                    trigger: 'blur'
                }],

                level: [{
                    required: true,
                    message: '请选择会员等级',
                    trigger: 'change'
                }],

                password: [{
                    required: true,
                    message: '请输入密码',
                    trigger: 'change'
                }],

                password2: [{
                    required: true,
                    message: '请输入密码',
                    trigger: 'change'
                }, {
                    validator: validatePasswordConsistent, trigger: 'change'
                }],

                clubcardId: [{
                    required: true,
                    message: '请选择会员卡',
                    trigger: 'blur'
                }],

                remark: [{
                    required: true,
                    message: '请输入备注',
                    trigger: 'blur'
                }]
            },

            levelOptions: [{
                "label": "普通会员",
                "value": "普通会员"
            }, {
                "label": "高级会员",
                "value": "高级会员"
            }],
        }
    },
    computed: {},
    methods: {
        submitForm() {
            this.$refs['form'].validate(valid => {
                if (!valid) return

                let req = this.$fuu.copy(this.upsertReq)
                let pwdMd5 = md5(req.password)
                req.password = pwdMd5

                delete req['password2']
                // see: https://bigloser.atlassian.net/wiki/spaces/LosersGrou1/pages/1638408/B#[inlineExtension]添加会员
                this.$http.post('/members', req).then(resp => {
                    this.$fuu.promptingCheckSuccess(resp)
                    if (resp.ok) this.$emit('success')
                    else this.$emit('failure')
                }).catch(_err => {
                    console.error("Error creating c user.")
                    this.$emit('failure')
                })

            })
        },

        resetForm() {
            this.$refs['form'].resetFields()
        }

    }
}


</script>
<style>
</style>
