<template>
  <div class="avatar-uploader">

    <input
        ref="input"
        type="file"
        name="image"
        accept="image/*"
        @change="setImage"
    />

    <div class="content">
      <section class="cropper-area">
        <div class="img-cropper">
          <vue-cropper
              ref="cropper"
              :src="imgSrc"
              class="cropper"
              preview=".preview"
              :autoCrop="true"
              :autoCropArea="0.8"
              :high="false"
          />
        </div>

        <div class="actions">
          <a
              id="upload-img"
              href="#"
              role="button"
              @click.prevent="showFileChooser"
          >
            <el-button type="primary" size="default">
              <i class="iconfont icon-folder-open" style="margin-right: 2px"></i>
              选择图片
            </el-button>
          </a>

          <a
              href="#"
              role="button"
              @click.prevent="cropImage"
          >
            <el-button size="default" type="success">
              <i class="iconfont icon-correct-3" style="margin-right: 2px"/>
              确定
            </el-button>
          </a>

          <a
              href="#"
              role="button"
              @click.prevent="reset"
          >
            <el-button size="default" type="default">
              <i class="iconfont icon-cancel-1" style="margin-right: 2px"/>
              重置
            </el-button>
          </a>

        </div>

      </section>

      <section class="preview-area">
        <div class="title">预览</div>
        <div class="previews">
          <div class="preview round"></div>
          <div class="preview rectangle"></div>
        </div>
      </section>

    </div>
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css'
import VueCropper from 'vue-cropperjs'

export default {
  components: {VueCropper},

  props: {
    cropperImgSize: {
      type: String,
      default: '28rem'
    },

    src: {
      type: String,
      default: undefined,
    }
  },

  beforeMount() {
    this.imgSrc = this.src
  },

  data() {
    return {
      imgSrc: undefined,
      cropImg: '',
      data: null
    };

  },

  methods: {
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      console.log('Uploading avatar.')
      let req = {data: this.cropImg}

      this.$http.post('/upload-b64', req).then(resp => {
        if (resp.ok) {
          let imgUrl = resp.data
          this.$emit('upload-success', imgUrl)
          this.$emit('success', imgUrl)
          console.log('Upload success, url: ', imgUrl)
        } else {
          this.$message.error('上传失败!')
        }
      })
    },

    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },

    reset() {
      this.$refs.cropper.reset();
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },

    showFileChooser() {
      this.$refs.input.click();
    },

  },
};
</script>
<style lang="scss">
@import "../assets/styles/variables.scss";

$cropperAreaWidth: 30rem;

.avatar-uploader {
  width: 100%;
}

input[type="file"] {
  display: none;
}

.content {
  display: flex;
  flex-direction: row;
}

.cropper-area {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $cropperAreaWidth;
  border-right: 1px solid grey;

  .img-cropper {
    width: $cropperAreaWidth;
    height: $cropperAreaWidth;

    display: flex;
    justify-content: center;
    align-items: center;

    .cropper {
      width: calc(0.9 * #{$cropperAreaWidth});
      height: calc(0.9 * #{$cropperAreaWidth});
    }
  }

  .actions {
    margin-top: 8px;

    a {
      display: inline-block;
      color: white;
      background-color: white;

      padding-left: 5px;
      padding-right: 5px;
    }

  }


}


.preview-area {
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    font-size: $titleFontSize;
    font-weight: $titleFontWeight;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .previews {
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: space-evenly;
    height: 100%;


    .preview {
      overflow: hidden;
      border-radius: 50%;
      border: 1px;
      margin-top: calc(0.32 * #{$cropperAreaWidth} * 0.3);
      margin-bottom: calc(0.32 * #{$cropperAreaWidth} * 0.3);
      width: calc(0.32 * #{$cropperAreaWidth}) !important;
      height: calc(0.32 * #{$cropperAreaWidth}) !important;
    }

    .preview.round {
      border-radius: 50%;
    }

    .preview.rectangle {
      margin-top: 10px;
      border-radius: 8%;
    }

  }

}


.cropped-image img {
  max-width: 100%;
}

</style>