<template>
</template>

<script>
export default {
  name: "ClubCardTmpl"
}
</script>

<style scoped>

</style>