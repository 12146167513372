import fuu from "@/utils/frequently-used-utils"
import constants from "@/utils/constants"

const userState = {
    namespaced: true,

    state: {
        theName: undefined,
        token: undefined,

        info: undefined,
        arena: undefined,
        permissions: [],
        roles: []
    },

    mutations: {

        setToken(state, token, persistDays = 15) {
            console.log('Saving token.')
            state.token = token
            fuu.persistItem(constants.UMS_USER_TOKEN_KEY, token, persistDays * 24 * 3600)
        },

        /**
         * userInfo, e.g. {
         *     info: {
         *         avatar: 'https://some-image.png',
         *         nickname: '',
         *     },
         *     arena: {
         *         _id: $someObjId,
         *     },
         *
         *     permission: ['p1', 'p2'],
         *     roles: ['r1', 'r2']
         * }
         */
        updateUserInfo(state, userInfo) {
            state.info = userInfo['info']
            state.arena = userInfo['arena']
        },

        clearUserInfo(state) {
            state.token = undefined
            state.info = undefined
            state.arena = undefined
            state.permissions = []
            state.roles = []
        }
    },

    getters: {
        token(state) {
            let token = state.token
            if (fuu.strEmpty(token)) {
                token = fuu.getPersistenceItem(constants.UMS_USER_TOKEN_KEY)
                if (!fuu.strEmpty(token)) {
                    state.token = token
                }
            }
            return token
        },

        curUserArena(state) {
            return state.arena
        }
    }
}

export default userState