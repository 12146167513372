<template>
    <div class="component-container">


        <div style="width: 100%;">
            <el-form ref="form" :model="upsertReq" :rules="rules" size="medium" label-width="150px">

                <div class="row">
                    <el-form-item label="区域名称" prop="name">
                        <el-input v-model="upsertReq.name" placeholder="请输入区域名称" clearable
                                  :style="{width: '50%'}">
                        </el-input>
                    </el-form-item>
                </div>

                <el-form-item label="运动" prop="sportCode">
                    <el-select v-model="upsertReq.sportCode" placeholder="请选择运动" clearable :style="{width: '30%'}">
                        <el-option v-for="(item, index) in sports" :key="index" :label="item.name"
                                   :value="item.code" :disabled="item.disabled"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="营业时间" prop="openFromTo">
                    <el-time-select v-model="upsertReq.openFromTo[0]" :style="{width: '20%'}"
                                    start="06:00" end="23:00" step="01:00"
                                    start-placeholder="开始时间" end-placeholder="结束时间" range-separator="至"
                                    clearable></el-time-select>

                    <section style="padding-left: 10px; padding-right: 10px;">至</section>

                    <el-tooltip :content="openToTip">
                        <el-time-select v-model="upsertReq.openFromTo[1]" :style="{width: '20%'}"
                                        start="06:00" end="23:00" step="01:00"
                                        start-placeholder="开始时间" end-placeholder="结束时间" range-separator="至"
                                        clearable></el-time-select>
                    </el-tooltip>

                </el-form-item>

                <el-form-item label="提前预定天数" prop="cReserveDaysInAdvance">
                    <el-input-number
                            v-model="upsertReq.cReserveDaysInAdvance" placeholder="提前预定天数" :step='1' :min='3'
                            :max='14'>
                    </el-input-number>
                </el-form-item>

                <el-form-item label="可取消订单天数" prop="cancelOrderWithinDays">
                    <el-input-number
                            v-model="upsertReq.cancelOrderWithinDays" placeholder="可取消订单天数" :step='1' :min='0'
                            :max='7'>
                    </el-input-number>
                </el-form-item>


                <el-form-item label="单价" prop="pricePerHour">
                    <el-input-number
                            v-model="upsertReq.pricePerHour"
                            placeholder="单价"
                            :step="1"
                            :precision="0"
                            controls-position="right"/>
                </el-form-item>

                <el-form-item label="创建场地" prop="createCourts" required>
                    <el-switch v-model="upsertReq.createCourts"></el-switch>
                </el-form-item>

                <div class="row" style="display: flex; flex-direction: row" v-if="upsertReq.createCourts">

                    <div class="col" style="width: 40%;">
                        <el-form-item label="场地数量" prop="courtsNum">
                            <el-input-number
                                    v-model="upsertReq.courtsNum" placeholder="场地数量" :step='1'
                                    controls-position=right :min='1' :max='100'></el-input-number>
                        </el-form-item>
                    </div>

                    <div class="col">
                        <el-form-item label="场地名后缀" prop="courtNameSuffix" label-width="8rem">
                            <el-input v-model="upsertReq.courtNameSuffix" placeholder="号场" clearable
                                      :style="{width: '100%'}">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div class="col" style="width: 33%;">
                        <el-form-item prop="customizeCourtNames" label="自定义场地名称" label-width="11rem">
                            <el-checkbox v-model="upsertReq.customizeCourtNames"></el-checkbox>
                        </el-form-item>
                    </div>

                </div>

                <el-form-item label="场地" v-if="upsertReq.createCourts">

                    <div class="courts-customizing-name" v-if="upsertReq.customizeCourtNames">
                        <el-input
                                class="court-name-input" style="margin-bottom: 5px;"
                                v-for="(court, idx) in upsertReq.courts" v-model="court.name"
                                :key="idx">
                        </el-input>
                    </div>

                    <div class="courts" v-else>
                        <div class="court" v-for="(court, idx) in upsertReq.courts" :key="idx">
                            {{ court.name }}
                        </div>
                    </div>

                </el-form-item>

                <el-form-item label="备注" prop="remark">
                    <el-input v-model="upsertReq.remark" type="textarea" placeholder="请输入备注"
                              :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
                </el-form-item>

                <el-form-item size="medium">
                    <el-button type="primary" @click="submitForm">
                        <i class="iconfont icon-correct-3" style="margin-right: 2px"></i>
                        提交
                    </el-button>
                    <el-button @click="resetForm">
                        <i class="iconfont icon-cancel-1" style="margin-right: 2px"></i>
                        重置
                    </el-button>
                </el-form-item>

            </el-form>

        </div>

    </div>
</template>
<script>

export default {


    data() {
        return {

            sports: [],

            upsertReq: {
                arenaId: undefined,
                name: undefined,
                sportCode: undefined,
                openFromTo: ["09:00", "23:00"],
                cReserveDaysInAdvance: 7,
                cancelOrderWithinDays: 1,
                pricePerHour: 50,
                createCourts: true,
                courtsNum: 1,
                courtNameSuffix: undefined,
                customizeCourtNames: false,
                courts: [],
                remark: undefined,
            },

            rules: {

                name: [{
                    required: true,
                    message: '请输入区域名称',
                    trigger: 'blur'
                }],

                sportCode: [{
                    required: true,
                    message: '请选择运动',
                    trigger: 'change'
                }],

                openFromTo: [{
                    validator: (rule, value, callback) => {
                        if (value.length < 2) callback(new Error('请选择营业时间'))

                        let t1 = value[0], t2 = value[1] // 09:00, 23:00
                        if (t1 >= t2) {
                            callback(new Error('开始时间必须小于营业时间'))
                        }

                        callback()
                    },
                    trigger: 'blur'
                }],

                cReserveDaysInAdvance: [{
                    required: true,
                    message: '请输入提前预定天数',
                    trigger: 'blur'
                }],

                cancelOrderWithinDays: [{
                    required: true,
                    message: '请输入可取消订单天数',
                    trigger: 'blur'
                }],

                pricePerHour: [{
                    required: true,
                    message: '请输入单价',
                    trigger: 'blur'
                }],

                courtsNum: [{
                    required: true,
                    message: '场地数量不能为空',
                    trigger: 'blur'
                }]

            }

        }
    },

    watch: {

        'upsertReq.courtsNum'(v2, v1) {
            if (v2 && v2 !== v1) {
                this.genCourts()
            }
        },

        'upsertReq.courtNameSuffix'(v2, v1) {
            if (v2 != null && v2 !== v1) {
                this.genCourts()
            }
        }

    },

    computed: {
        openToTip() {
            let tStr = this.upsertReq.openFromTo[1] // 23:00
            let hour = tStr.substring(0, tStr.length - 3)
            return `营业至${hour}点, 用户最晚可定${hour - 1}点场次`
        }
    },

    created() {
        this.$http.get('/sports').then(resp => {
            this.upsertReq.arenaId = this.$store.state.user.arena._id
            this.sports = resp.data
        })
    },

    mounted() {
        if (this.upsertReq.createCourts && this.upsertReq.courtsNum > 0 && this.upsertReq.courts.length === 0) {
            console.log("Creat courts but court arr empty, gen courts.")
            this.genCourts()
        }
    },

    methods: {

        submitForm() {
            this.$refs['form'].validate(valid => {
                if (!valid) return

                let req = this.$fuu.copy(this.upsertReq)
                let [a, b] = req.openFromTo // 09:00, 23:00

                let t0 = parseInt(a.split(':')[0])
                let t1 = parseInt(b.split(':')[0])
                t1 = t1 - 1 // 23 -> 22

                req.openFromTo = [t0, t1] // [9, 22]

                this.$http.post('/ams/regions', req).then(resp => {
                    let ok = this.$fuu.promptingCheckSuccess(resp)
                    if (ok) this.$emit('success')
                })

            })
        },

        resetForm() {
            this.$refs['form'].resetFields()
        },

        genCourts() {
            let courts = []
            for (let i = 0; i < this.upsertReq.courtsNum; i++) {

                let suffix = this.upsertReq.courtNameSuffix
                if (this.$fuu.strEmpty(suffix)) suffix = '号场'

                let courtName = `${i + 1}${suffix}`

                let court = {name: courtName}
                courts.push(court)
            }

            this.upsertReq.courts = courts
        }

    }
}

</script>
