<template>
    <div class="deletable-image-container">
        <div class="img-wrapper" :style="imgWrapperStyle">
            <el-image :src="src" style="width: 100%; height: 100%" :style="imgStyle"/>
            <div class="delete-bar flex-center" @click="deleteImage">
                <i class="iconfont icon-delete" style="font-size: 30px; color: white"></i>
            </div>
        </div>
        <div class="delete-bar-2"/>
    </div>
</template>

<script>
export default {
    name: "DeletableImage",
    props: {

        src: {
            type: String
        },

        meta: {
            default: null
        },

        height: {
            type: String,
            default: null,
        },

        width: {
            type: String,
            default: '80%'
        },

        padding: {
            type: String,
            default: '0px',
        },

        imgBorderRadius: {
            type: String,
            default: '0px'
        },

        deletable: {
            type: Boolean,
            default: true
        }

    },

    data() {
        let imgWrapperStyle = {
            padding: this.padding,
            width: this.width,
            height: this.height
        }

        if (!this.height) {
            delete imgWrapperStyle['height']
        }

        return {
            imgWrapperStyle,
            imgStyle: {
                'border-radius': this.imgBorderRadius
            }

        }
    },

    methods: {
        deleteImage() {
            this.$confirm(
                '确定删除图片吗?',
                '确认删除',
                {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(_yes => {
                this.$message.success('OK')
                this.$emit('deleted', this.src, this.meta)
            }).catch(_ => null) // cancel
        }
    }
}
</script>

<style scoped lang="scss">

$deleteBarHeight: 64px;

.deletable-image-container {
  .delete-bar {
    height: $deleteBarHeight;
    top: -$deleteBarHeight;

    background-color: #2b2b2b;
    opacity: 0.3;
    position: relative;
  }

  .delete-bar:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}


</style>