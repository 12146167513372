<template>
    <div class="container">
        <div class="content-block daily-orders-chart">

        </div>

        <div class="content-block daily-orders-chart-wrapper flex-center">
            <v-chart
                    :option="dailyOrdersChartOption"
                    class="daily-orders-chart"
                    autoresize
            />
        </div>

        <div class="content-block occupancy-rate-charts flex-row">
            <div
                    v-for="(pieChartOpt, idx) in occupancyRateChartOptions"
                    class="occupancy-rate-chart-wrapper flex-row"
                    :key="idx"
            >
                <v-chart
                        :option="pieChartOpt"
                        class="occupancy-rate-chart"
                        autoresize
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, onBeforeUnmount} from "vue"

import * as echarts from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {
    GridComponent,
    TitleComponent,
    TooltipComponent,
    LegendComponent
} from 'echarts/components'


import {
    LineChart,
    GaugeChart
} from "echarts/charts"
import VChart from "vue-echarts"

import homeService from "@/ums/services/home-service"

echarts.use([
    CanvasRenderer,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    LineChart,
    GaugeChart
])

const dailyOrdersChartOption = ref({})
const occupancyRateChartOptions = ref([])

reload()
let reloadTaskHandle = setInterval(_ => {
    reload()
}, 30 * 1000)
onBeforeUnmount(_ => {
    clearInterval(reloadTaskHandle)
})

function reload() {
    homeService.fetchDailyOrdersChart().then(opt => {
        dailyOrdersChartOption.value = opt
    })

    homeService.fetchOccupancyRateCharts().then(opts => {
        occupancyRateChartOptions.value = opts // ignore IDE warning
    })
}
</script>

<style lang="scss">
@import "../../assets/styles/variables";

.container {
  .daily-orders-chart-wrapper {
    width: $contentBodyWidth;
    padding: 0 20px;

    .daily-orders-chart {
      width: 100%;
      height: 550px;
    }
  }

  .occupancy-rate-charts {
    flex-wrap: wrap;
    width: $contentBodyWidth;
    justify-content: space-between;
    padding: 0 12%;

    .occupancy-rate-chart-wrapper {
      width: calc(#{$contentBodyWidth} / 2.8);
      height: calc(#{$contentBodyWidth} / 4);

      .occupancy-rate-chart {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
