import axios from "axios"
import store from "@/store"

const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
})

const httpClient = {
    get(url, params = {}) {
        return doAxiosRequestCall('GET', url, params)
    },

    post(url, data) {
        return doAxiosRequestCall('POST', url, data)
    },

    put(url, data) {
        return doAxiosRequestCall('PUT', url, data)
    },

    delete(url, data) {
        return doAxiosRequestCall('DELETE', url, data)
    },

    call(method, url, data) {
        return doAxiosRequestCall(method, url, data)
    },

    doUpsert(url, upsertReq, idKey = "_id") {
        let id = upsertReq[idKey]
        if (id == null) { // update
            return this.post(url, upsertReq)
        } else {
            return this.put(url + `/${id}`, upsertReq)
        }
    }

}

function doAxiosRequestCall(method, url, req) {
    let headers = assembleHeaders()

    let uniReqOpt = {
        method,
        url,
        headers,
    }

    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
        uniReqOpt.data = req
    } else {
        uniReqOpt.url = url + '?q=' + JSON.stringify(req)
    }

    return new Promise((resolve, reject) => {
        http.request({
            ...uniReqOpt,
        }).then(httpResp => {
            let commonResp = extractCommonResp(httpResp)
            resolve(commonResp)
        }).catch(err => {
            reject(err)
        })
    })
}

function assembleHeaders() {
    let token = store.getters["user/token"]

    let headers = {}
    headers['Authorization'] = token

    return headers
}

/**
 * @param {Object} resp, the http resp object returned by uni.request, e.g.
 {
		"data":{"data":{"user":{"name":"Tom"}},"errCode":0,"errMsg":"OK"},
		"statusCode":200,
	 ...}
 */
function extractCommonResp(resp) {
    let commonResp = resp.data
    if (commonResp.errCode === 0) {
        commonResp.ok = true
        tryFillPageMeta(commonResp)
    }
    return commonResp
}

function tryFillPageMeta(commonResp) {
    let data = commonResp.data
    if (!(data instanceof Object)) return false

    if ('pageNum' in data && 'totalRecs' in data) { // is page
        let page = data
        page['totalPages'] = Math.ceil(page['totalRecs'] / page['pageSize'])
        return true
    }
    return false
}

export default httpClient
export {
    http,
    httpClient
}
