<template>
    <div class="account-container link">
        <div class="avatar-n-nickname" @click="expanded = !expanded">

            <div class="avatar img-wrapper">
                <el-image
                        class="img"
                        :src="user.avatar"
                />
            </div>

            <div class="nickname flex-row">
                {{ user.nickname }}
            </div>

            <div class="arrow-indicator">
                <i class="iconfont icon-sort-down-1" v-if="!expanded"/>
                <i class="iconfont icon-sort-up" v-else/>
            </div>
        </div>

        <div class="expanded-menus flex-center" :class="expanded ? 'active': ''" v-if="expanded">
            <div class="expanded-menu flex-center link" @click="logout">退出登录</div>
        </div>

    </div>
</template>

<script setup>
import userService from "@/ums/services/user-service"
import {reactive, ref} from "vue"
import fuu from "@/utils/frequently-used-utils"
import router from "@/router"

let expanded = ref(false)
const user = reactive({
    nickname: undefined,
})

reload()
document.addEventListener('click', e => {
    if (!fuu.eventTriggeredByEleWithClasses(e, ['account-container'])) {
        expanded.value = false
    }
})

function reload() {
    userService.ensureUserInfo().then(userInfo => {
        Object.assign(user, userInfo)
    })
}

function logout() {
    userService.logout().then(_ok => {
        router.push('/login')
    })
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/variables";

.account-container {
  color: white;
  width: $topBarAccountBlockWidth;
  margin-right: 20px;

  right: 0;

  .avatar-n-nickname {
    width: $topBarAccountBlockWidth;

    display: flex;
    align-items: center;

    height: $topBarHeight;

    .avatar.img-wrapper {
      height: calc(#{$topBarHeight} * 0.7);
      width: calc(#{$topBarHeight} * 0.7);

      .img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .nickname {
      flex: 1;
      padding-left: 10px;
    }

    .arrow-indicator {
      width: 18px;
      height: 18px;
    }
  }

  .expanded-menus {
    width: $topBarAccountBlockWidth;
    position: absolute;
    transition: height 1.0s;
    margin-top: 4px;

    .expanded-menu {
      width: 100%;
      color: black;
      padding: 5px;
    }

  }

  .expanded-menus.active {
    z-index: 1024;
    min-height: 30px;
    background-color: white;

    transition: height 1.0s;
    margin-top: 4px;
    box-shadow: 1px 1px 1px 2px grey;

    .expanded-menu:hover {
      background-color: #f2f2f2;
    }
  }

}


</style>
