<template>
  <div>
    <el-transfer ref="transfer" v-model="checkedUserIds" :data="items" :titles="['备选用户', '选中用户']" filterable>
      <template v-slot:left-footer>
        <div class="flex-center" style="border: 1px solid red; padding-top: 5px">
          <el-button type="primary" size="small">加载更多</el-button>
        </div>
      </template>
    </el-transfer>
  </div>
</template>

<script>
export default {
  name: 'RoleUser',
  props: ['role'], // role: role object

  created() {
    this.reload()
  },

  data() {
    return {
      items: [],
      checkedUserIds: [],
    }
  },

  methods: {
    reload() {
      this.$http.get('/ums/users').then(resp => {
        this.items = resp.data.data
        convertUsers(this.items)
      })

      this.$http.get(`/ums/roles/${this.role._id}/users`).then(resp => {
        let userRoleRelations = resp.data.data
        this.checkedUserIds = userRoleRelations.map(u => u['userId'])
      })
    }
  }

}

function convertUsers(users) {
  users.forEach(u => {
    u.label = u.nickname
    u.key = u._id
  })
}
</script>

<style lang="scss">
.el-transfer-panel:nth-child(3) {
  position: relative;
  top: -25px;

  .el-transfer-panel__filter {
    border: 1px solid red;
    display: None;

  }
}

</style>
