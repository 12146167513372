<template>
  <div class="container">

    <el-table :data="items">
      <el-table-column label="plz">
        <template #default="scope">
          {{ scope.row.nickname }}
        </template>
      </el-table-column>
      <el-table-column label="昵称" prop="nickname"/>
      <el-table-column label="邮箱" prop="email"/>
      <el-table-column label="登录名" prop="username"/>
    </el-table>

    <el-button type="primary" size="small" @click="showUpsertDialog = 1">
      添加
    </el-button>

    <el-dialog title="添加用户" :model-value="showUpsertDialog > 0" @close="showUpsertDialog = 0" width="600px">
      <el-form ref="form" :model="form" :rules="rules" size="medium" label-width="125px">
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入昵称" clearable :style="{width: '100%'}"></el-input>
        </el-form-item>
        <el-form-item label="登录名" prop="username">
          <el-input v-model="form.username" placeholder="请输入登录名" clearable :style="{width: '100%'}"></el-input>
        </el-form-item>
        <el-form-item label="请输入密码" prop="password_1">
          <el-input v-model="form.password_1" placeholder="请输入请输入密码" clearable show-password
                    :style="{width: '100%'}"></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="password_2">
          <el-input v-model="form.password_2" placeholder="请输入重复密码" clearable show-password
                    :style="{width: '100%'}"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form.email" placeholder="请输入邮箱" clearable :style="{width: '100%'}"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea" placeholder="备注" :maxlength="255" show-word-limit
                    :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
        </el-form-item>
        <el-form-item size="meidum">
          <el-button type="primary" @click="submitForm">提交</el-button>
          <el-button @click="$refs.form.resetFields()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "User",

  created() {
    this.reload()
  },

  data() {
    return {

      loading: false,
      query: {pageNum: 1, pageSize: 10},
      items: [],

      showUpsertDialog: 0,

      form: {
        avatar: 'https://my-southchina-open-bucket.oss-cn-guangzhou.aliyuncs.com/62d8eccd396f3614c0cff758.png',
        nickname: undefined,
        username: undefined,
        password_1: undefined,
        password_2: undefined,
        email: undefined,
        remark: undefined,
      },

      rules: {
        nickname: [{
          required: true,
          message: '请输入昵称',
          trigger: 'blur'
        }],
        username: [{
          required: true,
          message: '请输入登录名',
          trigger: 'blur'
        }],
        password_1: [{
          required: true,
          message: '请输入请输入密码',
          trigger: 'blur'
        }],
        password_2: [{
          required: true,
          message: '请输入重复密码',
          trigger: 'blur'
        }],
        email: [{
          required: true,
          message: '请输入邮箱',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/i,
          message: '请输入正确的邮箱',
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    reload() {
      this.$fuu.loadItems(this, () => this.$http.get('/ums/users', {data: this.query}))
    },

    submitForm() {
      let req = this.$fuu.copy(this.form)
      req['password'] = req['password_1']

      delete req['password_1']
      delete req['password_2']

      this.$http.post('/ums/users', req)
          .then(resp => {
            let ok = this.$fuu.promptingCheckSuccess(resp)
            if (ok) this.reload()
            this.showUpsertDialog = 0
          })
          .catch(_err => {
            this.$message.error('操作失败!')
          })
    }
  }

}
</script>

<style scoped>

</style>
