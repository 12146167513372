import store from "@/store"
import router from "@/router"

function ensureMenus() {
    return new Promise(resolve => {
        if (store.state.menu.menus?.length > 0) {
            console.log('Already got menus')
            resolve(store.state.menu.menus)
            return
        }

        console.log('Loading menus.')
        store.commit('menu/setMenus', menus)
        console.log('Got menus, saved to store.')
        resolve(menus)
    })
}

/**
 *
 * @param tab
 * @param routerPushState
 */
function openTab(tab, routerPushState = 1) {
    let state = store.state.menu

    if (state.activeTab === tab) {
        return
    }

    let found = state.selectedTabs.find(t => t._id === tab._id)
    if (!found) {
        state.selectedTabs.push(tab)
    }

    state.activeTab = tab
    let activeMenu = findRootMenuOfMenuBar(state.id2Menu, tab) // ignore ide warning
    store.commit('menu/setActiveMenu', activeMenu)

    if (routerPushState === 0) {
        // no push
    } else if (routerPushState === 1) {
        router.push(tab.path).then(_ => null)
    } else if (routerPushState === 2) {
        router.push(tab.__fullPath).then(_ => null)
    }

}

function switchTab(tab) {
    openTab(tab, 2)
}

function findRootMenuOfMenuBar(id2Menu, curTabMenu) {
    if (curTabMenu._id === 0) { // home
        return curTabMenu
    }

    while (true) {
        if (!curTabMenu.parentId) return curTabMenu; // not parent
        curTabMenu = id2Menu[curTabMenu.parentId]
    }
}

function closeTab(tab) {
    restoreTabName(tab)

    let state = store.state.menu

    if (tab._id === 0) { // you cannot close this tab
        state.activeTab = tab
        return // you cannot close the home page
    }

    let tabIdx = state.selectedTabs.findIndex(t => t._id === tab._id)
    if (tabIdx === -1) {
        console.warn("Didn't find matching tab, sth must went wrong, tab:", tab)
        return
    }

    let prevTab = state.selectedTabs[tabIdx - 1]
    state.selectedTabs.splice(tabIdx, 1)
    if (state.activeTab === tab) {
        switchTab(prevTab)
    }

}

function correctActiveRootMenu() {
    let tab = store.state.menu.activeTab
    let activeRootMenu = findRootMenuOfMenuBar(store.state.menu.id2Menu, tab)
    store.commit('menu/setActiveMenu', activeRootMenu)
}

function changeCurTabName(name) {
    let tab = currentTab()
    tab.originalName = tab.name
    tab.name = name
}

function currentTab() {
    return store.state.menu.activeTab
}

function restoreCurTabName() {
    let tab = currentTab()
    restoreTabName(tab)
}

function restoreTabName(tab) {
    if (tab.originalName != null && tab.originalName.length > 0) {
        tab.name = tab.originalName
        delete tab['originalName']
    }
}

function closeTabsToLeft(tab) {
    let shouldCloseTabs = []

    for (let candidateTab of store.state.menu.selectedTabs) {
        if (candidateTab._id === 0) continue // home page

        if (candidateTab._id !== tab._id) {
            shouldCloseTabs.push(candidateTab)
        } else {
            break
        }
    }

    for (let shouldCloseTab of shouldCloseTabs) {
        closeTab(shouldCloseTab)
    }
}

function closeTabsToRight(tab) {

    let shouldCloseTabs = []

    let started = false
    for (let candidateTab of store.state.menu.selectedTabs) {
        if (candidateTab._id === 0) continue // root

        if (candidateTab._id === tab._id) {
            started = true
        } else {
            if (!started) continue
            else shouldCloseTabs.push(candidateTab)
        }
    }

    for (let shouldCloseTab of shouldCloseTabs) {
        closeTab(shouldCloseTab)
    }

}

function closeTabsExcept(tab) {

    function shouldClose(candidateTab) {
        if (candidateTab._id === tab._id) return false
        if (candidateTab._id === 0) return false // is home page
        return true
    }

    let shouldCloseTabs = store.state.menu.selectedTabs.filter(t => shouldClose(t))
    for (let shouldCloseTab of shouldCloseTabs) {
        closeTab(shouldCloseTab)
    }

}

export default {
    ensureMenus,
    openTab,
    switchTab,
    closeTab,
    correctActiveRootMenu,
    changeCurTabName,
    restoreCurTabName,

    closeTabsToLeft,
    closeTabsToRight,
    closeTabsExcept
}


// ==========================================================
// Mocked data goes here
// ==========================================================
const menus = [
    {
        _id: 1,
        name: '场馆',
        icon: 'changguan',
        children: [
            {
                _id: 11,
                name: '场地管理',
                children: [
                    {
                        _id: 111, name: '场馆详情',
                        path: '/ams/arena-detail'
                    },
                    {
                        _id: 112, name: '区域管理', path: '/ams/region',
                        children: [
                            {
                                _id: 1121, name: '区域详情', displayed: false, path: '/ams/region-detail'
                            }
                        ]
                    },
                ]
            }
        ]
    }, {
        name: '运营',
        icon: 'yunying',
        _id: 2,
        children: [
            {
                _id: 21,
                name: "运营管理",
                children: [
                    {
                        _id: 211,
                        path: '/operation/clubcard-template',
                        name: '会员卡模板'
                    },

                    {
                        _id: 212,
                        path: '/operation/member',
                        name: '会员管理'
                    },
                    {
                        _id: 213,
                        path: '/operation/order',
                        name: '订单管理'
                    }

                ]
            }
        ]
    }

]