const HOME_MENU = {_id: 0, name: '首页', path: '/', __fullPath: '/'}

const menuState = {
    namespaced: true,

    state: {
        // side bar related menu
        id2Menu: {},
        path2Menu: {},

        menus: [],
        activeMenu: HOME_MENU, // No menu

        // top bar tab related menus
        selectedTabs: [HOME_MENU],
        activeTab: HOME_MENU
    },

    mutations: {
        setMenus(state, menus) {
            state.menus = menus
            state.id2Menu = {}
            dfsBuildSchStructure(state, menus)

            state.path2Menu['/'] = HOME_MENU
            state.id2Menu[0] = HOME_MENU
        },

        setActiveMenu(state, menu) {
            state.activeMenu = menu
        },

        setSelectedTabs(state, selectedTabs) {
            state.selectedTabs = selectedTabs
        }

    }
}

/**
 * For each menu,
 * 1) set menu.parentId
 * 2) build id2Menu dict for quick findById
 * 3) build path2Menu dict for quick find by path
 */
function dfsBuildSchStructure(state, menus) {
    menus.forEach(m => {
        state.id2Menu[m._id] = m
        if (m.path && m.path.length > 0) state.path2Menu[m.path] = m

        if (m.children) {
            m.children.forEach(child => child.parentId = m._id)
            dfsBuildSchStructure(state, m.children)
        }
    })
}

export default menuState