import http from "@/utils/request"
import store from '@/store'
import fuu from '@/utils/frequently-used-utils'
import constants from "@/utils/constants"
import * as util from "util";

/**
 * @param params e.g. {
 *    username: 'admin',
 *    password: '123456',
 *    rememberMe: true
 * }
 */
function login(params) {
    return new Promise((resolve, reject) => {
        http.post('/login', params).then(resp => {
            if (resp.ok) {
                console.log('Login success, saving token!')

                let token = resp.data
                store.commit('user/setToken', token)

                resolve(resp)
            } else {
                resolve(resp)
            }
        }).catch(err => reject(err))
    })
}

/**
 * could raise not logged in error
 * @returns
 */
function ensureUserInfo() {
    return new Promise((resolve, reject) => {
        let hasToken = gotToken()
        if (!hasToken) {
            reject('Not logged in')
            return
        }

        let userInfo = store.state.user?.info
        if (userInfo != null) {
            resolve(userInfo)
            console.log('Got user info from store.')
            return
        }

        console.log('Fetching user info.')
        http.get('/users/me').then(resp => { // TODO: change to _me
            if (resp.ok) {
                let userInfo = resp.data
                store.commit('user/updateUserInfo', userInfo)
                console.log('Got user info, save to store.')
                resolve(userInfo)
            } else {
                reject("Dint' find user info")
            }
        }).catch(err => reject(err))
    })
}

function getToken() {
    let ok = gotToken()
    if (!ok) return null
    return store.state.user.token
}

function gotToken() {
    let token = store.getters["user/token"]
    return token != null && token.length > 0
}

function logout() {
    return new Promise(resolve => {
        store.commit('user/clearUserInfo')
        fuu.removePersistenceItem(constants.UMS_USER_TOKEN_KEY)
        resolve(true)
    })
}

function loggedIn() {
    let token = store.getters["user/token"]
    return !fuu.strEmpty(token)
}

export default {
    login,
    loggedIn,
    ensureUserInfo,
    getToken,
    logout
}
