<template>
  <div class="crm-container">
    <div class="rd-info-box">
      <!--      <div class="left-padding"></div>-->
      <div class="rd-info-scroll-view" ref="bs1">
        <div style="display: inline-block">
          <div class="content">
            <div v-for="(rd, idx) in reserveDayInfos" :key="idx" class="day flex-center"
                 :class="selectedDay === rd ? 'active': ''"
                 @click="dateClicked(rd)">
              <div>{{ rd['weekdayCn'] }}</div>
              <div>{{ rd['dateCn'] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="reserve-mat">
      <div class="left">
        <div class="empty-padding"></div>
        <div class="hours" ref="bs2">
          <div class="content">
            <div class="hour-box" v-for="(hourStr, idx) in hours" :key="idx">
              <div class="hour">{{ hourStr }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="right">

        <div class="courts-scroll-view" ref="bs3">
          <div class="wrapper" style="display: inline-block">
            <div class="courts">
              <div class="court-wrapper" v-for="(vec, idx) in mat" :key="idx">
                <div class="court"> {{ vec.court.name }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="content-scroll-view" ref="bs4">
          <div class="content-wrapper" style="display: inline-block">
            <div class="content">
              <div v-for="(vec, idx) in mat" :key="idx" class="vec">
                <div v-for="(courtHour, idx2) in vec.hours" :key="idx2" class="court-hour-wrapper">
                  <div class="court-hour" :class="courtHour.available ? 'available': 'unavailable'">
                    <div class="info" v-if="courtHour.available">
                      <span style="color: white; margin-right: 5px">¥</span>
                      {{ courtHour.pricePerHour }}
                    </div>

                    <div v-if="(!courtHour.available) && (!courtHour.user)"
                         class="info marked-unavailable">
                      <div class="price flex-center">
                        <span style="color: white; margin-right: 2px">¥</span>
                        {{ courtHour.pricePerHour }}
                      </div>
                    </div>

                    <div v-if="(!courtHour.available) && courtHour.user" class="info user">
                      <div class="img-wrapper flex-center">
                        <el-image
                            class="img"
                            v-if="courtHour.user?.portrait"
                            :src="courtHour.user?.portrait"
                        />
                        <div v-else
                             class="user-surname flex-center"
                             :style="{backgroundColor: userBackGroundColor(courtHour.user)}">
                          {{ courtHour.user.nickname.substring(0, 1) }}
                        </div>
                      </div>

                      <div class="price flex-row">
                        <span style="color: white; margin-right: 2px">¥</span>
                        {{ courtHour.pricePerHour }}
                      </div>
                    </div>

                    <div class="operation flex-center">
                      <el-dropdown trigger="click">
                        <div class="icon-wrapper flex-center">
                          <i class="iconfont icon-down-triangle-1"></i>
                        </div>
                        <template #dropdown>
                          <el-dropdown-menu v-if="courtHour.available">
                            <el-dropdown-item @click="markUnavailable(courtHour)">
                              标记不可用
                            </el-dropdown-item>
                          </el-dropdown-menu>

                          <el-dropdown-menu
                              v-if="(!courtHour.available) && (!courtHour.orderId)">
                            <el-dropdown-item @click="markAvailable(courtHour)">标记可用
                            </el-dropdown-item>
                          </el-dropdown-menu>

                          <el-dropdown-menu
                              v-if="(!courtHour.available) && courtHour.orderId">
                            <el-dropdown-item @click="openViewOrderDrawer(courtHour)">
                              查看订单
                            </el-dropdown-item>
                          </el-dropdown-menu>

                        </template>
                      </el-dropdown>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <el-drawer v-model="showViewOrderDrawer" direction="rtl" size="20rem" title="订单详情">
      <order-viewer :order-id="orderId" width="23rem"/>
    </el-drawer>

  </div>
</template>

<script>
import http from "@/utils/request"
import httpClient from "@/utils/request"
import ClubCardTmpl from "@/ams/views/ClubCardTmpl"
import regionService from '@/ams/services/region-service'
import BScroll from "better-scroll"
import OrderViewer from "@/ams/components/OrderViewer"
import fuu from "@/utils/frequently-used-utils";

export default {
  name: "CRM2", // TODO: explain why this stupid name, court reservation mat?
  components: {OrderViewer, ClubCardTmpl},

  props: {
    regionId: {
      type: String
    }
  },

  created() {
    let url = `/ams/regions/${this.regionId}/reserve-day-info`
    let p1 = http.get(url, {days: 30}) // how many days in advance can I reserve
    let p2 = http.get('/ams/courts', {regionId: this.regionId, pageNum: 1, pageSize: 100}) // courts of this region

    Promise.all([p1, p2]).then(respArr => {
      this.reserveDayInfos = respArr[0].data
      this.courts = respArr[1].data.items
      this.dateClicked(this.reserveDayInfos[0])
    })

  },

  beforeUnmount() {
    clearInterval(this.reloadMatIntervalId)
  },

  computed: {
    hours() {
      if (this.mat) {
        return this.mat[0].hours.map(courtHour => courtHour.hour)
      }

      let arr = []
      for (let i = 9; i <= 22; i++) {
        arr.push(`${i}点`)
      }

      return arr
    }
  },


  data() {
    return {
      courts: [],
      reserveDayInfos: [],
      selectedDay: undefined,
      mat: undefined,
      R2: {},

      bs1: undefined,
      bs2: undefined,
      bs3: undefined,
      bs4: undefined,

      showMarkUnavailableCourtHours: false,
      markUnavailableCourtHours: [],

      showViewOrderDrawer: false,
      orderId: undefined,

      reloadMatIntervalId: undefined
    }
  },

  methods: {

    openMarkCourtHoursUnavailableDialog(courtHours) {
      this.markUnavailableCourtHours = courtHours
      this.showMarkCourtHourAvailable = true
    },

    openViewOrderDrawer(courtHour) {
      this.orderId = courtHour.orderId
      this.showViewOrderDrawer = true
    },

    dateClicked(date) {
      this.selectedDay = date
      this.reloadMat(true)

      console.log('Setting interval.')
      clearInterval(this.reloadMatIntervalId)

      this.reloadMatIntervalId = setInterval(_ => {
        this.reloadMat(false)
      }, 10 * 1000)
    },

    reloadMat(ensureScrollable = false) {
      let date = this.selectedDay
      let url = `/ams/regions/${this.regionId}/reserve-info-mat`
      http.get(url, {dateGmt: date['dateGmt']}).then(resp => {

        /*
         * {
         *   openFromTo: [9, 22],
         *   unavailableCourtHours: [], not sure of the element detail
         * }
         */
        let res = resp.data
        this.mat = regionService.buildReserveInfoMat(this.courts, res.openFromTo, res['unavailableCourtHours'])

        if (ensureScrollable) {
          this.ensureScrollable()
        }
      })
    },

    markUnavailable(courtHour) {
      this.$fuu.letUserConfirm('确认标记不可用吗').then(confirmed => {
        if (!confirmed) return

        let url = '/court-hours/mark-unavailable'
        let req = this.$fuu.partialObj(courtHour, ['courtId', 'hour'])
        req['dateGmt'] = this.selectedDay.dateGmt

        this.$http.put(url, req).then(resp => {
          let ok = this.$fuu.promptingCheckSuccess(resp)
          if (ok) {
            courtHour.available = false
          }
        })
      })
    },

    ensureScrollable() {
      for (let i = 0; i < 4; i++) {
        if (this['bs' + i]) {
          this['bs' + i].destroy()
        }
      }

      setTimeout(() => {
        this.$nextTick(() => {
          this.bs1 = new BScroll(this.$refs.bs1, {scrollX: true, scrollY: false, momentum: false})
          this.bs2 = new BScroll(this.$refs.bs2, {scrollX: false, scrollY: true, momentum: false})
          this.bs3 = new BScroll(this.$refs.bs3, {scrollX: true, scrollY: false, momentum: false})
          this.bs4 = new BScroll(this.$refs.bs4, {
            scrollX: true,
            scrollY: true,
            momentum: false,
            disableMouse: true
          })

          this.bs2.scroller.actions.hooks.on('coordinateTransformation', () => {
            this.bs4.scrollTo(this.bs3.x, this.bs2.y)
          })

          this.bs2.scroller.scrollBehaviorX.hooks.on('end', (momentumInfo) => {
            this.bs4.scrollTo(this.bs3.x, this.bs2.y)
          })

          this.bs3.scroller.actions.hooks.on('coordinateTransformation', () => {
            this.bs4.scrollTo(this.bs3.x, this.bs2.y)
          })

          this.bs3.scroller.scrollBehaviorX.hooks.on('end', (momentumInfo) => {
            this.bs4.scrollTo(this.bs3.x, this.bs2.y)
          })

        })
      }, 100)
    },

    markAvailable(courtHour) {

      fuu.letUserConfirm('确定标记可用吗?').then(confirmed => {
        if (!confirmed) {
          this.$message.error('fucker')
          return
        }

        let url = '/court-hours/mark-available'
        let req = fuu.partialObj(courtHour, ['courtId', 'hour'])
        req['dateGmt'] = this.selectedDay['dateGmt']

        httpClient.put(url, req).then(resp => {
          let ok = fuu.promptingCheckSuccess(resp)
          if (ok) {
            courtHour.available = true
          }
        })
      })

    },

    userBackGroundColor(user) {
      let nickname = user.nickname
      return fuu.randomDarkColor(nickname)
    }

  }
}
</script>


<style scoped lang="scss">
@import "../../assets/styles/variables";
@import "../styles/variables";

$dateHeight: 60px;
$timeBarWidth: 80px;

$vecWidth: 150px;
$courtHeight: 50px;
$courtHourHeight: 60px;

.crm-container {

  .rd-info-box {
    height: $dateHeight;
    width: 100%;
    display: flex;
    flex-direction: row;

    .rd-info-scroll-view {
      width: calc(#{$regionDetailContentWidth} - 5px);
      overflow: hidden;
      border: 1.5px solid $themeColor;
      border-radius: 5px;

      .content {
        display: flex;
        flex-direction: row;

        .day {
          height: $dateHeight;
          flex-shrink: 0;
          width: 80px;
          display: flex;
          flex-direction: column;
          background-color: #f9f9f9;
          border-bottom: 5px solid #f9f9f9;
          cursor: move;
        }

        .day.active {
          border-bottom: 5px solid $themeColor;
          background-color: $themeColor;
          color: white;
          font-weight: 600;
        }

      }
    }
  }

  .reserve-mat {
    width: 100%;
    height: calc(#{$regionDetailContentHeight} - #{$dateHeight});

    display: flex;
    flex-direction: row;

    .left {
      width: $timeBarWidth;
      height: 100%;

      .empty-padding {
        height: $courtHeight;
        width: 100%;
      }

      .hours {
        width: $timeBarWidth;
        height: calc(#{$regionDetailContentHeight} - #{$dateHeight} - #{$courtHeight});
        overflow: hidden;
        // border: 2px solid $themeColor;
        border-radius: 5px;

        .content {
          width: 100%;
          display: flex;
          flex-direction: column;

          .hour-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: $courtHourHeight;
            cursor: move;

            padding: 3px;
          }

          .hour {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border: 1px solid grey;
            border-radius: 5px;
          }

        }
      }
    }

    .right {
      width: calc(100% - #{$timeBarWidth});

      .courts-scroll-view {
        width: 100%;
        height: $courtHeight;
        border-radius: 3px;
        overflow: hidden;

        .courts {
          display: flex;
          flex-direction: row;

          .court-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $vecWidth;
            flex-shrink: 0;
            height: $courtHeight;
            cursor: move;
            padding: 3px;

            .court {
              display: flex;
              justify-content: center;
              align-items: center;

              width: 100%;
              height: 100%;
              border: 1px solid grey;
              border-radius: 5px;
            }
          }

        }
      }

      .content-scroll-view {
        width: 100%;
        height: calc(100% - #{$courtHeight});

        overflow: hidden;

        .content {
          display: flex;
          flex-direction: row;

          .vec {
            width: $vecWidth;

            .court-hour-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              height: $courtHourHeight;

              width: $vecWidth;
              flex-shrink: 0;
              padding: 3px;

              .court-hour {
                display: flex;
                justify-content: center;
                align-items: center;

                height: 100%;
                width: 100%;
                color: white;

                .info {
                  height: 100%;
                  width: 86.5%;

                  display: flex;
                  justify-content: center;
                  align-items: center;

                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                }

                .info.user {


                  display: flex;
                  flex-direction: row;
                  align-items: center;


                  .img-wrapper {
                    height: calc(#{$courtHourHeight} * 0.8);
                    width: calc(#{$courtHourHeight} * 0.8);
                    padding: 5%;

                    .img {
                      border-radius: 50%;
                    }

                    .user-surname {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                    }
                  }
                }

                .operation {
                  flex: 1;
                  color: #1f1e1e;
                  font-size: $titleFontSize;
                  border-top: 1px solid grey;
                  border-right: 1px solid grey;
                  border-bottom: 1px solid grey;
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                  height: 100%;
                  cursor: pointer;

                  .icon-wrapper {
                    height: calc(#{$courtHourHeight} * 0.85);
                  }
                }
              }

              .court-hour.available {
                .info {
                  background-color: #1bcb1b;
                }
              }

              .court-hour.unavailable {
                .info {
                  background-color: grey;
                }
              }

            }

          }
        }
      }

    }

  }

}

</style>