<template>

    <div class="court-container">
        <div class="query" style="display: none">
            <el-input v-model="query.nameLike" style="width: 300px" size="small">
                <template #prepend>场地名</template>
            </el-input>
            <el-button type="" size="medium" style="margin-left: 10px" @click="reload">搜索</el-button>
        </div>

        <div class="operation-bar">
            <el-button type="primary" size="default" style="margin-left: 10px" @click="openAddDialog">
                <i class="iconfont icon-add-2"></i>
                添加
            </el-button>
        </div>

        <div class="content">
            <el-table :data="items">
                <el-table-column
                        label="#"
                        type="index"
                        align="center">
                </el-table-column>

                <el-table-column prop="name" label="名称" width="150rem" align="center"/>
                <el-table-column prop="pricePerHour" label="单价" width="150rem" align="center"/>
                <el-table-column prop="remark" label="备注" width="180rem" align="center"/>

                <el-table-column label="操作" align="center" width="120em">
                    <template #default="scope">
                        <div class="flex-center">
                            <rec-op-bar
                                    :show-view="false"
                                    @edit="openUpdateDialog(scope.row)"
                                    @delete="openDeleteDialog(scope.row)"
                            />
                        </div>

                    </template>
                </el-table-column>

            </el-table>
        </div>

        <el-dialog v-model="showUpsertDialog" :title="dialogTitle" width="450px">
            <el-form ref="form" :model="upsertReq" :rules="rules" size="medium" label-width="80px">

                <div class="row">
                    <el-form-item label="场地名称" prop="name">
                        <el-input v-model="upsertReq.name" placeholder="请输入场地名称" clearable
                                  :style="{width: '50%'}">
                        </el-input>
                    </el-form-item>
                </div>

                <el-form-item label="单价" prop="remark">
                    <el-input-number
                            v-model="upsertReq.pricePerHour" placeholder="单价" :step='1'
                            controls-position=right></el-input-number>
                </el-form-item>

                <el-form-item label="备注" prop="remark">
                    <el-input v-model="upsertReq.remark" type="textarea" placeholder="请输入备注"
                              :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitForm">
                        <i class="iconfont icon-correct-3"
                           style="margin-right: 2px"></i>
                        提交
                    </el-button>
                    <el-button @click="resetForm">
                        <i class="iconfont icon-cancel-1" style="margin-right: 2px"></i>
                        重置
                    </el-button>
                </el-form-item>

            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import http from "@/utils/request"
import Temp from "@/Temp";
import RecOpBar from "@/components/RecOpBar";

export default {
    name: "Court",
    components: {RecOpBar},
    props: {
        regionId: {
            type: String
        },

        regionDefaultPrice: { // region price per hour
            type: Number
        }
    },

    created() {
        this.reload()
    },

    computed: {
        dialogTitle() {
            if (this.showUpsertDialog === 1) return '添加场地'
            else if (this.showUpsertDialog === 2) return '修改场地'
        }
    },

    data() {
        return {
            query: {
                regionId: this.regionId,
                nameLike: undefined,

                pageNum: 1,
                pageSize: 10
            },

            items: [],

            totalPages: 0,
            totalRecs: 0,

            showUpsertDialog: 0,

            upsertReq: {
                _id: undefined,
                regionId: undefined,
                pricePerHour: this.regionDefaultPrice,
                name: undefined,
                remark: undefined,
            },

            rules: {
                name: [{
                    required: true,
                    message: '请输入名称',
                    trigger: 'blur'
                }]
            }

        }
    },

    methods: {

        reload() {
            http.get('/ams/courts', this.query).then(resp => {
                if (resp.ok) {
                    let page = resp.data

                    this.items = page.items
                    this.totalRecs = page.totalRecs
                    this.totalPages = page.totalPages
                }
            })
        },

        openAddDialog() {
            this.$fuu.clearFields(this.upsertReq)
            this.upsertReq.regionId = this.regionId

            this.showUpsertDialog = 1
        },

        openUpdateDialog(row) {
            this.$fuu.populateFields(this.upsertReq, row)
            this.showUpsertDialog = 2
        },

        resetForm() {
            for (let k in this.upsertReq) {
                this.upsertReq[k] = undefined
            }
        },

        submitForm() {
            this.$refs.form.validate(valid => {
                if (!valid) return

                this.$http.doUpsert('/ams/courts', this.upsertReq).then(resp => {
                    let ok = this.$fuu.promptingCheckSuccess(resp)
                    if (ok) {
                        this.showUpsertDialog = 0
                        this.reload()
                    }
                })

            })
        },

        openDeleteDialog(row) {
            this.$confirm(
                '危险操作，确认删除场地吗?',
                '确认删除',
                {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(_yes => {
                let url = '/ams/courts/' + row._id
                this.$http.delete(url).then(resp => {
                    let ok = this.$fuu.promptingCheckSuccess(resp)
                    if (ok) this.reload()
                })
            }).catch(_ => null) // cancel
        }

    }
}
</script>

<style scoped lang="scss">
.query {
  width: 100%;
  height: 50px;
  border: 1px solid orange;

  display: flex;
  align-items: center;

  background-color: white;
}

.content {
  background-color: white;
}

.ops {
  display: flex;
  flex-direction: row;

  .op {
    padding-left: 5px;
    padding-right: 5px;
  }

  .op:nth-child(1) {
    color: orange;
  }

  .op:nth-child(2) {
    color: red;
  }
}
</style>