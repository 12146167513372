<template>
    <div class="container">
        <div class="query-bar">
            <div class="wrapper" style="width: 250px">
                <el-input size="medium" v-model="query.nameLike" placeholder="用户昵称, 手机号">

                </el-input>
            </div>

            <el-button size="medium">搜索</el-button>
        </div>

        <el-table :data="items">
            <el-table-column label="运动" align="center">
                <template #default="scope">
                    <div style="width: 100%; height: 100%;" class="flex-center">
                        <div class="img-wrapper">
                            <el-image class="img" :src="scope.row.sport.imgUrl"
                                      style="width: 100%; height: 100%"></el-image>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="区域" align="center">
                <template #default="scope">
                    <div style="color: #409eff">{{ scope.row.region.name }}</div>
                </template>
            </el-table-column>

            <el-table-column label="场地" prop="courtNames" align="center">

            </el-table-column>

            <el-table-column label="会员" align="center">
                <template #default="scope">

                    <div style="width: 100%; height: 100%;" class="flex-center">
                        <div class="img-wrapper" v-if="scope.row.memberInfo?.portrait">
                            <el-image class="img" :src="scope.row.memberInfo.portrait"
                                      style="width: 100%; height: 100%"></el-image>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="日期" prop="dateGmt" align="center">

            </el-table-column>

            <el-table-column label="时长" align="center">
                <template #default="scope">
                    {{ scope.row.courtHours.length + '小时' }}
                </template>
            </el-table-column>

            <el-table-column label="起止时间" prop="fromToDesc" align="center">
            </el-table-column>

            <el-table-column label="金额" prop="price" align="center">
            </el-table-column>

            <el-table-column label="支付方式" align="center">
                <template #default="scope">
                    <div v-if="scope.row.payment.type=== 'clubcard'" class="flex-center payment-clubcard">
                        <el-tooltip
                                effect="dark"
                                :content="scope.row.payment.card.name"
                                placement="top">
                            <div class="img-wrapper">
                                <el-image :src="scope.row.payment.card.imgUrl" class="img"/>
                            </div>
                        </el-tooltip>
                    </div>
                    <div v-else>现金</div>
                </template>
            </el-table-column>

            <el-table-column label="订单状态" align="center">
                <template #default="scope">
                    <div class="state-wrapper flex-center">
                        <div v-if="scope.row.state==='PAYED'" class="state payed flex-center">已支付</div>
                        <div v-if="scope.row.state=== 'CANCELED'" class="state canceled flex-center">已取消</div>
                        <div v-if="scope.row.state=== 'FULFILLED'" class="state fulfilled flex-center">已完成</div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="创建时间" prop="createTime" align="center">
            </el-table-column>

            <el-table-column label="创建时间" prop="createTime" align="center">
                <template #default="scope">
                    <div class="op-box">
                        <button-view size="small" @click="orderId = scope.row._id; showViewOrderDrawer=true"/>
                    </div>
                </template>
            </el-table-column>

        </el-table>

        <div class="pagination-wrapper" style="display: flex; justify-content: flex-end; margin-top: 10px">
            <el-pagination
                    v-model:current-page="query.pageNum"
                    v-model:page-size="query.pageSize"
                    :page-sizes="[1, 10, 20, 50]" background
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalRecs"
                    @size-change="reload"
                    @current-change="reload"
            />
        </div>
    </div>

    <el-drawer v-model="showViewOrderDrawer" direction="rtl" size="20rem" title="订单详情">
        <order-viewer :order-id="orderId" width="18rem"/>
    </el-drawer>
</template>

<script>
import http from "@/utils/request"
import ButtonAdd from "@/components/ButtonAdd"
import ButtonView from "@/components/ButtonView"
import OrderViewer from "@/ams/components/OrderViewer"
import Temp from "@/Temp";
import userService from "@/ums/services/user-service";

export default {
    name: "Member",
    components: {Temp, ButtonView, ButtonAdd, OrderViewer},

    created() {
        userService.ensureUserInfo().then(_ => {
            this.arenaId = this.$store.state.user.arena._id
            this.query.arenaId = this.$store.state.user.arena._id
            this.reload()
        })
    },

    data() {
        return {
            arenaId: undefined,

            query: {
                arenaId: undefined,
                pageNum: 1,
                pageSize: 10,
            },

            items: [],
            totalRecs: 0,
            totalPages: 0,

            showViewOrderDrawer: false,
            orderId: undefined,
        }
    },

    methods: {
        reload() {
            http.get(`/operation/orders`, this.query).then(resp => {
                let page = resp.data
                this.items = page.items

                this.totalRecs = page.totalRecs
                this.totalPages = page.totalPages
            })
        }
    }

}
</script>

<style scoped lang="scss">
$opBarHeight: 60px;

.query-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;

  height: $opBarHeight;
  padding-left: 5px;

  .wrapper {
    margin-right: 10px;
  }

}

.op-bar {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: $opBarHeight;
  padding-left: 5px;

}

.img-wrapper {
  width: 60px;
  height: 60px;
  padding: 2px;

  .img {
    border-radius: 5px;
  }

}

.payment-clubcard {
  .img-wrapper {
    width: 5.5rem;
    height: 3rem;
    padding: 3px;

    .img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
}

.state-wrapper {

  .state {
    color: white;
    width: 50px;
    height: 25px;
    border-radius: 3px;
  }

  .state.payed {
    background-color: #409eff;
  }

  .state.canceled {
    background-color: grey;
  }

  .state.fulfilled {
    background-color: green;
  }

}


</style>