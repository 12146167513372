<template>
  <div class="container">

    <div class="lock">
      <i class="iconfont" style="font-size: 25px; border: 1px solid green"
         :class="canEdit ? 'icon-unlock-fill': 'icon-lock-fill'" @click="canEdit = !canEdit"/>
    </div>

    <div class="container-content">
      <div class="left">
        <div class="menu" :key="menu._id" v-for="menu in menus"
             :class="currentMenu === menu ? 'active': ''" @click="currentMenu=menu"
        >{{ menu.name }}
        </div>
        <div class="menu" v-if="canEdit">
          <el-button type="primary" size="small" @click="displayUpsertDialog">添加目录</el-button>
        </div>
      </div>

      <div class="right-part">
        <div v-for="subMenu in subMenus" class="sub-menu-block" :key="subMenu.id">
          <div class="category">{{ subMenu.name }}</div>
          <div class="category-content">
            <div class="menu-and-items" v-for="menuAndItems in subMenu.children">
              <div class="menu flex-center">{{ menuAndItems.name }}</div>
              <div class="items">
                <div class="item" v-for="item in menuAndItems.children">
                  {{ item.name }}
                </div>

                <div class="item" v-if="canEdit">
                  <el-button size="small" @click="displayUpsertDialog(null, menuAndItems._id)">添加页面组件</el-button>
                </div>
              </div>
            </div>

            <div class="menu-and-items" v-if="canEdit">
              <div class="menu">
                <el-button
                    size="small" type="primary"
                    @click="displayUpsertDialog(null, subMenu._id, true)">添加菜单
                </el-button>
              </div>
            </div>
          </div>
        </div>

        <div class="sub-menu-block" style="margin-top: 10px" v-if="canEdit">
          <div class="category">
            <el-button type="primary" size="small" @click="displayUpsertDialog(null, currentMenu._id)">添加菜单分组
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="新增菜单" :model-value="showUpsertDialog > 0" @close="showUpsertDialog = 0"
               width="500px">

      <el-form ref="form" :model="form" :rules="rules" size="small" label-width="125px">
        <el-form-item label="菜单名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入菜单名称" clearable :style="{width: '100%'}">
          </el-input>
        </el-form-item>
        <el-form-item label="ICON" prop="icon">
          <el-input v-model="form.icon" placeholder="请输入ICON" clearable :style="{width: '100%'}"></el-input>
        </el-form-item>
        <el-form-item label="权限字段" prop="permission">
          <el-input v-model="form.permission" placeholder="请输入权限字段" clearable :style="{width: '100%'}">
          </el-input>
        </el-form-item>

        <el-form-item label="路径" prop="path" v-if="showPathAndComponent">
          <el-input v-model="form.path" placeholder="请输入权限字段" clearable :style="{width: '100%'}">
          </el-input>
        </el-form-item>

        <el-form-item label="组件" prop="component" v-if="showPathAndComponent">
          <el-input v-model="form.component" placeholder="请输入权限字段" clearable :style="{width: '100%'}">
          </el-input>
        </el-form-item>

        <el-form-item size="small">
          <el-button type="primary" @click="submitForm">提交</el-button>
          <el-button @click="$refs['form'].resetFields()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>


</template>

<script>

export default {
  name: "Menu",

  created() {
    this.reload()
  },

  computed: {
    subMenus() {
      if (this.currentMenu && this.currentMenu.children) return this.currentMenu.children
      return []
    }
  },

  data() {
    return {
      canEdit: false,
      currentMenu: null,
      menus: [],

      showUpsertDialog: 0, // 0: close, 1: view, 2 edit
      showPathAndComponent: false, // control if to show path and component
      form: {
        parentId: undefined,
        name: undefined,
        icon: undefined,
        permission: undefined,
        path: undefined,
        component: undefined
      },
      rules: {
        name: [{
          required: true,
          message: '请输入菜单名称',
          trigger: 'blur'
        }],
        icon: [{
          required: true,
          message: '请输入ICON',
          trigger: 'blur'
        }]
      },
    }
  },


  methods: {

    reload(activeSubMenuIdx = 0) {
      this.$http.get('/ums/menus')
          .then(resp => {
            this.menus = resp.data.data
            this.currentMenu = this.menus[activeSubMenuIdx]
          })
    },

    displayUpsertDialog(menu, parentId = null, showPathAndComponent = false) {
      if (this.$refs.form) this.$refs.form.resetFields()
      this.form.parentId = parentId

      if (menu) {
        let fields = ['id', 'name', 'permission', 'icon', 'parentId']
        fields.forEach(k => this.form[k] = menu[k])
      }

      this.showUpsertDialog = 2
      this.showPathAndComponent = showPathAndComponent

    },

    submitForm() {

      let promise = null

      if (!this.form.id) {
        promise = this.$http.post('/ums/menus', this.form)
      } else {
        promise = this.$http.put('/ums/menus', this.form)
      }


      promise.then(resp => {
        this.$message.success('操作成功')
        this.showUpsertDialog = 0
        let curMenuIdx = this.menus.findIndex(m => m === this.currentMenu)
        this.reload(curMenuIdx)
      }).catch(_err => {
        this.showUpsertDialog = 0
      })

    }
  }
}
</script>

<style scoped lang="scss">
.container-content {
  display: flex;

  .left {
    border: 1px solid red;
    width: 150px;

    .menu {
      background-color: #f2f2f2;
      height: 40px;
      border: 1px solid hotpink;
      display: flex;
      padding-left: 20px;
      align-items: center;
      font-weight: 600;
    }

    .menu.active {
      background-color: white;
    }
  }

  .right-part {
    border: 1px solid green;
    flex: 1;
    height: 800px;

    .sub-menu-block {
      padding-left: 10px;
      display: flex;
      flex-direction: column;

      .category {
        border: 1px solid cyan;
        width: 100%;
        height: 40px;
        font-weight: 550;
        display: flex;
        align-items: center;
        background-color: white;
      }

      .category-content {
        border: 1px solid red;
        width: 100%;

        .menu-and-items {
          display: flex;
          flex-direction: row;
          align-items: center;
          min-height: 40px;
          border: 1px solid red;

          .menu {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: hotpink;
            width: 100px;
            border: 1px solid black;
          }

          .items {
            display: flex;
            flex-direction: row;

            .item {
              padding-right: 10px;
            }
          }
        }
      }
    }


  }
}
</style>
