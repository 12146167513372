<template>
  <div class="container">
    <order-viewer order-id="6449d3afefa572ddc771d18d"></order-viewer>
  </div>
</template>

<script>
import OrderViewer from "@/ams/components/OrderViewer"

export default {
  name: "Temp4",

  created() {
    console.log('Hello, world!')
  },

  components: {
    OrderViewer
  },

  data() {
    return {}
  }
}
</script>
