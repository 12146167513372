<template>
  <div class="img-box" :style="{width: width, height, padding}">
    <el-image :src="src" style="width: 100%; height: 100%" :style="{borderRadius: imgBorderRadius}"/>
  </div>
</template>

<script>
export default {
  name: "MyImage",
  props: {

    src: {
      type: String,
    },

    width: {
      type: String,
      default: '25rem',
    },

    height: {
      type: String,
      default: '25rem'
    },

    padding: {
      type: String,
      default: '0%'
    },

    imgBorderRadius: {
      type: String,
      default: '0%',
    }
  }
}
</script>