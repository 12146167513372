<template>
  <div class="container" :style="{width}">

    <div class="region the-title" v-if="order.region">{{ order.region.name }}</div>

    <div class="user-info" v-if="order.userInfo">
      <div class="img-box" v-if="order.userInfo.portrait">
        <el-image :src="order.userInfo.portrait" class="img"/>
      </div>
      <div class="user-name flex-center">{{ order.userInfo.nickname }}</div>
    </div>

    <div class="desc">{{ desc }}</div>

    <div class="court-hours" v-if="order.courtHours && order.courtHours.length > 0">

      <div class="content">
        <div v-for="(courtHour, idx) in order.courtHours" :key="idx" class="court-hour">
          <div class="ch-top flex-center">
            {{ courtHour.desc.split(' ')[1] }}
          </div>
          <div class="ch-bottom flex-center">
            {{ courtHour.desc.split(' ')[0] }}
          </div>
        </div>
      </div>

    </div>

    <div class="price"><span style="margin-right: 3px">¥</span>{{ order.price }}</div>

    <div class="payment-info" v-if="order.payment">
      <div class="upper" style="margin-bottom: 5px">
        支付方式: {{ order.payment.card.name }}
      </div>

      <div class="lower" v-if="order.payment.card.imgUrl">
        <my-image :src="order.payment.card.imgUrl" width="80%" height="60%" img-border-radius="10px"/>
      </div>
    </div>

    <div class="state-block flex-row">
      <div class="title">订单状态</div>
      <div class="state flex-center" :class="order.state?.toLowerCase()">{{ orderStateName }}</div>
    </div>

  </div>
</template>

<script>
import MyImage from "@/components/MyImage"


const ORDER_STATE_MAP = {
  'PAYED': '已支付',
  'FULFILLED': '已完成',
  'CANCELED': '已取消'
}

export default {
  name: "OrderViewer",
  components: {MyImage},
  props: {
    orderId: {
      type: String,
    },

    width: {
      type: String,
      default: '30rem'
    }
  },

  beforeMount() {
    if (this.$fuu.strNotEmpty(this.orderId)) {
      this.$http.get('/operation/orders/' + this.orderId).then(resp => {
        this.order = resp.data
      })
    }
  },

  watch: {
    orderId(v2, v1) {
      console.log('Got order id', v2)
      if (this.$fuu.strNotEmpty(v2) && v2 !== v1) {
        this.$http.get('/operation/orders/' + this.orderId).then(resp => {
          this.order = resp.data
        })
      }
    }
  },

  computed: {
    desc() {
      if (!this.order.reserveDay) return ''
      let dateCn = this.order.reserveDay.dateCn // 4月29日
      let weekDayCn = this.order.reserveDay.weekdayCn // 周六
      let sportName = this.order.sport.name
      return `${sportName} ${dateCn} ${weekDayCn}`
    },

    orderStateName() {
      return ORDER_STATE_MAP[this.order.state]
    }
  },

  data() {
    return {
      order: {}
    }
  }

}
</script>


<style scoped lang="scss">
@import "../../assets/styles/variables";

.container {

  .user-info {
    margin-top: 5px;
    display: flex;
    flex-direction: row;

    .img-box {
      width: 4rem;
      height: 4rem;

      .img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }

    .user-name {
      margin-left: 5px;
    }

  }

  .court-hours {
    .title {
      border: 1px solid green;
      margin-bottom: 5px;
    }

    .content {
      .court-hour {
        height: calc(#{$stdFontSize} * 4);
        width: 8rem;
        margin-right: 5px;

        display: flex;
        flex-direction: column;

        .ch-top {
          width: 100%;
          height: 50%;
          background-color: $themeColor;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          color: white;
        }

        .ch-bottom {

          width: 100%;
          height: 50%;
          border: 1px solid $themeColor;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

  .state-block {
    margin-top: 10px;
    align-items: center;

    .state {
      margin-left: 10px;
      width: 60px;
      height: 25px;
      border-radius: 6px;
      color: white;
    }

    .state.payed {
      background-color: #409eff;
    }

    .state.fulfilled {
      background-color: green;
    }

    .state.canceled {
      background-color: grey;
    }


  }
}

.container > div {
  margin-bottom: 5px;
}

</style>