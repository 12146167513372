/**
 * Copied from https://juejin.cn/post/7081176430834352164
 *
 */
const baseFontSize = 14
const minWindowWidth = 1024
const stdWindowWidth = 1400 // Think neo


function setRem() {
    let scale = document.documentElement.clientWidth / stdWindowWidth
    if (scale < minWindowWidth / stdWindowWidth) {
        scale = minWindowWidth / stdWindowWidth
    }
    document.documentElement.style.fontSize = baseFontSize * Math.min(scale, 2) + 'px'
}

setRem()

window.onresize = function () {
    setRem()
}
