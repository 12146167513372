import {createStore} from 'vuex'

import menuStore from "./modules/menu"
import userStore from "./modules/ums"
import fuu from "@/utils/frequently-used-utils"

export default createStore({
    actions: {},
    modules: {
        menu: menuStore,
        user: userStore
    }
})
