import store from '@/store'
import http from "@/utils/request";

function buildReserveInfoMat(courts, openFromTo, unavailableHours) {
    let builder = new RegionReserveInfoBuilder(courts, openFromTo, unavailableHours)
    return builder.buildReservationMat()
}

class RegionReserveInfoBuilder {

    constructor(courts, openFromTo, unavailableHours) {
        this.courts = courts // e.g. [{id: 'abcefg', 'pricePerHour': 30, name: '1号台'}]

        this.openFrom = openFromTo[0] // e.g. 9
        this.openTo = openFromTo[1] // e.g. 23
        this.unavailableHours = unavailableHours // [{courtId: 'abcd', hour: 9}, {courtId: 'efgh', hour: 10}]

        // results goes here
        this.resArr = []
    }

    buildReservationMat() {
        for (let court of this.courts) {
            this.generateHoursOfOneCourt(court)
        }
        return this.resArr
    }

    generateHoursOfOneCourt(court) {

        let courtReservationInfo = {
            court: court,
            hours: []
        }

        for (let h = this.openFrom; h <= this.openTo; h++) {

            let resource = {
                courtId: court._id,
                hour: h,
                available: true,
                pricePerHour: court.pricePerHour
            }

            // generate desc for later use
            let hourDesc = `${h}:00-${h + 1}:00`
            if (h < 10) {
                hourDesc = '0' + hourDesc // 09:00
            }
            resource.desc = `${court.name} ${hourDesc}`

            let idx = this.unavailableHours.findIndex(uh => {
                return uh.courtId === court._id && uh.hour === h
            })

            if (idx >= 0) {
                let courtHour = this.unavailableHours[idx]
                resource.available = false
                resource.user = courtHour.user
                resource.orderId = courtHour.orderId
            }

            courtReservationInfo.hours.push(resource)
        }

        this.resArr.push(courtReservationInfo)
    }

}

function fetchRegionsOfCurUser() {
    let arenaId = store.state.user.arena._id
    // One site can never have 1024
    return http.get('/ams/regions', {arenaId: arenaId, pageNum: 1, pageSize: 1024})
}

export default {
    fetchRegionsOfCurUser,
    buildReserveInfoMat
}
