<template>
  <div class="container">

    <div class="avatar-n-nickname">
      <div class="left-part flex-row">
        <div class="title">编辑场馆</div>
        <div class="right-part">
          <i class="iconfont icon-changguan"></i>
        </div>
      </div>

      <div class="right-part flex-row">
        <div class="buttons">
          <el-button size="default" @click="doCancel">
            <i class="iconfont icon-cancel-1" style="font-size: 0.85rem; margin-right: 1px"></i>
            取消
          </el-button>
          <el-button type="primary" size="default" @click="doSave">
            <i class="iconfont icon-correct-3"
               style="font-size: 0.95rem; margin-right: 1px"/>保存
          </el-button>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="left-part">

        <div class="logo">
          <el-image class="img" :src="arena.logo"></el-image>
          <a href class="edit-logo" @click.prevent="openEditLogoDialog">修改LOGO</a>
        </div>

        <div class="tabs">
          <div class="tab" @click="activeTab = 'basic-info'"
               :class="activeTab === 'basic-info' ? 'active': ''">
            基本信息
          </div>
          <div class="tab" @click="activeTab = 'images'" :class="activeTab === 'images' ? 'active': ''">
            图片集
          </div>
          <div class="tab" @click="activeTab = 'h5-detail'" :class="activeTab === 'h5-detail' ? 'active': ''"
               style="display: None">图文详情
          </div>
        </div>

      </div>

      <div class="right-part">
        <div v-if="activeTab === 'basic-info'" class="basic-info">

          <el-form ref="basicInfoForm" :model="arena" :rules="rules" size="default" label-width="100px">
            <el-form-item label="场馆名称" prop="name">
              <el-input v-model="arena.name" placeholder="请输入场馆名称" clearable
                        :style="{width: '350px'}"></el-input>
            </el-form-item>

            <el-form-item label="地址" prop="addr">
              <el-input
                  type="textarea"
                  v-model="arena.addr" placeholder="请输入地址" clearable :style="{width: '350px'}"
                  :autosize="{minRows: 4, maxRows: 4}">
              </el-input>
            </el-form-item>

            <el-form-item label="联系电话" prop="tel">
              <el-input v-model="arena.tel" placeholder="请输入联系电话" clearable
                        :style="{width: '350px'}"></el-input>
            </el-form-item>

          </el-form>

        </div>

        <div v-if="activeTab === 'images'" class="images">

          <div class="image-box" v-for="(imageUrl, idx) in arena.images" :key="idx"
               style="padding-left: 15px">
            <deletable-image
                :src="imageUrl"
                :meta="idx" @deleted="deleteImg"
                width="800px"
                img-border-radius="10px"/>
          </div>

          <div class="img-uploader">
            <el-upload
                :action="fileUploadUrl"
                :headers="fileUploadHeaders"
                :show-file-list="false"
                accept="image/*"
                :on-success="handleUploadImgSuccess"
            >
              <el-icon class="img-uploader-icon">
                <plus></plus>
              </el-icon>
            </el-upload>
          </div>


        </div>

        <div v-if="activeTab === 'h5-detail'">
          我是图文详情
        </div>

      </div>

    </div>

    <el-dialog v-model="showEditLogoDialog" width="750px" title="上传LOGO">
      <div class="flex-center">
        <avatar-upload v-on:upload-success="uploadAvatarSuccess" :src="arena.logo"/>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import ArenaBasicInfo from "@/ams/components/ArenaBasicInfo";
import ArenaImage from "@/ams/components/ArenaImage";
import ArenaH5Detail from "@/ams/components/ArenaH5Detail";
import AvatarUpload from "@/components/AvatarUpload"
import DeletableImage from "@/components/DeletableImage";
import {Plus} from '@element-plus/icons-vue'
import userService  from "@/ums/services/user-service"


export default {
  name: "ArenaDetail",
  components: {DeletableImage, ArenaH5Detail, ArenaImage, ArenaBasicInfo, AvatarUpload, Plus},

  props: {
    arena: {
      type: Object,
      default: undefined
    }
  },

  data() {
    return {
      fileUploadUrl: process.env.VUE_APP_API_BASE_URL + '/upload',

      activeTab: 'basic-info',
      showEditLogoDialog: false,

      rules: {
        name: [{
          required: true,
          message: '请输入场馆名称',
          trigger: 'blur'
        }],

        addr: [{
          required: true,
          message: '请输入地址',
          trigger: 'blur'
        }],

        tel: [{
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        }],
      },
    }
  },

  computed: {
    fileUploadHeaders() {
      return {
        'Authorization': userService.getToken()
      }
    }
  },


  methods: {

    openEditLogoDialog() {
      this.showEditLogoDialog = true
    },

    uploadAvatarSuccess(url) {
      this.arena.logo = url
      this.showEditLogoDialog = false
    },

    deleteImg(url, idx) {
      this.arena.images.splice(idx, 1)
    },

    doCancel() {
      console.log('User canceled editing arena detail.')
      this.$emit('canceled')
    },

    doSave() {

      let req = this.$fuu.copy(this.arena)
      let unwantedKeys = ['rating', 'regions', 'supportedSportObjs', 'supportedSports']
      this.$fuu.removeObjKeys(req, unwantedKeys)

      this.$http.put(`/arenas/${req._id}`, req).then(resp => {
        let ok = this.$fuu.promptingCheckSuccess(resp)
        if (ok) this.$emit('saved')
      }).catch(err => {
        console.log('Error updating arena detail, err: ', err)
        this.$message.error('操作失败!')
      })

    },

    handleUploadImgSuccess(resp) {
      if (resp.errCode === 0) {
        let imgUrl = resp.data
        if (this.arena.images == null) {
          this.arena.images = []
        }
        this.arena.images.push(imgUrl)
      }
    }

  }

}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables";


.container {
  background-color: white;
  height: calc(100vh - #{$topBarHeight});
}


$leftPartWidth: 10rem;

.avatar-n-nickname {

  display: flex;
  flex-direction: row;
  align-items: center;

  height: 40px;

  font-size: $titleFontSize;
  font-weight: $titleFontWeight;
  border-bottom: 0.06rem solid grey;

  .left-part {
    padding-left: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    width: $leftPartWidth;

    .iconfont {
      margin-left: 10px;
      font-size: calc(#{$titleFontSize} * 1.25);
    }

  }

}

.content {
  display: flex;
  flex-direction: row;

  .left-part {
    width: $leftPartWidth;
    border-right: 1px solid grey;

    .logo {
      width: $leftPartWidth;
      height: $leftPartWidth;
      padding: 20px;

      .img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

      .edit-logo {
        margin-top: 5px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .tabs {
      margin-top: 20px;

      .tab {
        display: flex;
        // justify-content: center;
        align-items: center;
        width: calc(0.8 * #{$leftPartWidth});
        margin-left: 20px;
        height: 40px;
        font-size: $titleFontSize;
      }

      .tab.active {
        background-color: #f0f5fe;
        color: #005bf5;
        font-weight: $titleFontWeight;
        border-radius: 5px;
      }

    }
  }

  .right-part {
    width: 100%;
    padding-top: 15px;

    .images {
      .image-box {
        //height: 600px;
      }
    }
  }

}

</style>

<style lang="scss">
.img-uploader .el-upload {
  margin-left: 20.25rem;
  border: 0.065px dashed grey;
  border-radius: 0.38rem;

  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.img-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.img-uploader-icon {
  font-size: 20px;
  color: #8c939d;
  width: 135px;
  height: 135px;
  text-align: center;
}
</style>