import store from '@/store'
import http from '@/utils/request'

function fetchArenaDetailOfCurUser() {
    // If user call this, user surely logged in, and got arena info
    let arena = store.state.user.arena
    console.log('Fetching arena of current user, id: ', arena._id)
    return http.get('/arenas/' + arena._id)
}

export default {
    fetchArenaDetailOfCurUser
}
