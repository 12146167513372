import dayjs from 'dayjs'
import {ElLoading, ElMessage, ElMessageBox} from 'element-plus'
import router from "@/router"
import http from "@/utils/request"
import {watch} from "vue"
import md5 from "md5"

function promptingCheckSuccess(resp) {
    let commonResp = 'errCode' in resp ? resp : resp.data

    if (commonResp.errCode === 0) {
        ElMessage.success('操作成功!')
    } else {
        let errMsg = commonResp['errMsg'] || '操作失败!'
        ElMessage.error(errMsg)
    }
    return commonResp.errCode === 0
}

function letUserConfirm(prompt, tip = '确认') {
    return new Promise(resolve => {
        ElMessageBox.confirm(prompt, tip, {
            type: 'warning',
            confirmButtonText: '确认',
            cancelButtonText: '取消',
        }).then(_yes => {
            resolve(true)
            ElMessageBox.close() // bug, Sometimes won't close
        }).catch(_cancel_err => {
            resolve(false)
            ElMessageBox.close() // bug, Sometimes won't close
        })
    })
}

function floatWithPrecision(val, precision) {
    let valStr = val.toFixed(precision)
    return parseFloat(valStr)
}

function loadItems(vueComponent, loadFunc, query = {}) {
    vueComponent.loading = true

    return new Promise((resolve, reject) => {
        loadFunc(query)
            .then(resp => {
                vueComponent.items = resp.data.data
                vueComponent.loading = false
                resolve(vueComponent.items)
            })
            .catch(_err => {
                vueComponent.loading = false
                reject(_err)
            })
    })

}

function persistItem(k, v, ttlSeconds = undefined) {
    let item = {v}
    if (ttlSeconds) {
        let t = dayjs().add(ttlSeconds, 'second')
        item['exp'] = t
    }
    let jsonStr = JSON.stringify(item)
    localStorage.setItem(k, jsonStr)
}

function getPersistenceItem(k) {
    let jsonStr = localStorage.getItem(k)
    if (!jsonStr) return null

    let obj = JSON.parse(jsonStr)
    let expStr = obj['exp']

    if (expStr) { // 2023-02-13T08:02:48.545Z
        let expDate = dayjs(expStr) // 2023-02-13T08:02:48.545Z
        let now = dayjs()
        let expired = now > expDate
        if (expired) {
            localStorage.removeItem(k)
            return null
        }
    }

    return obj.v
}

function removePersistenceItem(k) {
    localStorage.removeItem(k)
}

function copy(obj) {
    return JSON.parse(JSON.stringify(obj))
}


function removeObjKeys(obj, keys) {
    for (let k of keys) {
        if (!k in obj) continue
        delete obj[k]
    }
}

function strEmpty(s, trim = true) {
    if (s == null) return true
    if (trim) s = s.trim()
    return s.length === 0
}

function curUrlParams() {
    let r = router.currentRoute
    return r['_rawValue']['query']
}

function partialObj(srcObj, keys) {
    let rec = {}
    for (let k of keys) {
        rec[k] = srcObj[k]
    }
    return rec
}


function populateFields(tgtObj, srcObj) {
    for (let k in tgtObj) {
        tgtObj[k] = srcObj[k]
    }
}


function clearFields(obj) {
    for (let k in obj) {
        obj[k] = undefined
    }
}

function toHHMM(hour) {
    let s = `${hour}:00`
    if (hour < 10) s = '0' + s
    return s
}

/**
 *
 * @param signature
 * @returns {string} e.g. #abcdef
 */
function randomDarkColor(signature) {

    let r = 0, g = 0, b = 0

    if (strEmpty(signature)) {
        r = randomInt(100, 175)
        g = randomInt(80, 100)
        b = randomInt(100, 240)
    } else {
        let md5hex = md5(signature)
        let hashVal = parseInt(md5hex, 16)
        r = hashBetween(100, 175, hashVal)
        g = hashBetween(80, 100, hashVal)
        b = hashBetween(100, 240, hashVal)
    }

    return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`
}

function hashBetween(a, b, hashVal) {
    hashVal = hashVal < 0 ? -hashVal : hashVal
    let x = b - a
    return hashVal % x + a
}

function randomInt(a, b) {
    let x = Math.random() * (b - a) + a
    return parseInt(`${floatWithPrecision(x)}`)
}

function sleep(millis) {
    return new Promise(resolve => {
        setTimeout(_ => {
            resolve(true)
        }, millis)
    })
}

function reloadIfValChanges(watcherFunc, reloadFunc) {
    watch(watcherFunc, (v2, v1) => {
        if (v2 && v2 !== v1) {
            reloadFunc()
        }
    })
}

let fuu = {
    loadItems,
    floatWithPrecision,
    promptingCheckSuccess,
    copy,
    persistItem,
    getPersistenceItem,
    removePersistenceItem,
    removeObjKeys,
    strEmpty,
    curUrlParams,
    partialObj,
    populateFields,
    clearFields,
    toHHMM,
    randomDarkColor,
    sleep,
    letUserConfirm,
    reloadIfValChanges,
    wrapShowLoadingToFunc,
    eventTriggeredByEleWithClasses,
    strNotEmpty
}

function strNotEmpty(s) {
    return !strEmpty(s)
}

function wrapShowLoadingToFunc(returnPromiseFunc, ele) {

    function showLoadingFunc(...args) {
        return new Promise((resolve, reject) => {
            let loadingInstance = ElLoading.service({
                target: ele,
                fullscreen: false
            })

            returnPromiseFunc(args).then(resp => {
                loadingInstance.close()
                resolve(resp)
            }).catch(err => {
                loadingInstance.close()
                reject(err)
            })
        })
    }

    return showLoadingFunc
}

function eventTriggeredByEleWithClasses(event, classes) {
    let target = event.target
    while (true) {

        if (target.attributes) {
            let clazz = target.attributes['class']
            let className = clazz?.value

            if (!strEmpty(className)) {
                for (let tgtClass of classes) {
                    if (className.includes(tgtClass)) return true
                }
            }
        }

        target = target.parentElement

        if (!target) break
    }
    return false
}

export default fuu