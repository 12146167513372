<template>
  <div>
    <h1>Hello, world!</h1>
    <div>
      <div style="font-size: 16px">Hello, world2!</div>
    </div>

    <div class="block3">Fuck you!</div>

    <div style="border: 1px solid red" class="block4">
      <div class="flex-center">Hello, world!</div>
      <div class="flex-center">Hello, world!</div>
    </div>

    <button-add content="添加我" @click="$message.success('OK')"></button-add>

    <div>
      <el-button v-for="(btn, idx) in buttons" :key="idx" :type="btn.type">
        {{ 'Button' + btn.name + '->' + btn.type }}
      </el-button>
    </div>

    <div style="display: flex; flex-direction: row">
      <h1>Fuck you!</h1>
      <button-add content="添加我"></button-add>
      <el-button type="primary">Click me, click me!</el-button>
    </div>

    <el-drawer
        v-model="flag_2"
        title="添加产品"
        direction="btt"
        size="650px"
    >
    </el-drawer>

    <el-button @click="flag=true">Click me to open dialog!</el-button>

    <el-dialog v-model="flag">
      <h1>Fuck me!</h1>
    </el-dialog>

  </div>
</template>

<script>
import ButtonAdd from "@/components/ButtonAdd";

export default {
  components: {
    ButtonAdd
  },

  data() {
    return {
      flag: false,
      flag_2: true
    }
  },

  computed: {
    buttons() {
      let s = `"" | "default" | "primary" | "success" | "warning" | "info" | "danger" | "text"`
      let arr = s.split('|')

      let buttons = []
      for (let t of arr) {
        t = t.trim().replaceAll('"', '')

        buttons.push({
          type: t,
          name: t
        })
      }

      return buttons
    }
  }
}
</script>

<style lang="scss">
.block3 {
  height: 5rem;
  width: 8rem;
  border: 1px solid red;
}

.block4 {
  display: flex;
  flex-direction: row;

  div:nth-child(1) {
    width: calc(16px * 10);
    height: 2rem;
    border: 1px solid red;
    font-size: 16px;
  }

  div:nth-child(2) {
    width: calc(1rem * 10);
    height: 2rem;
    border: 1px solid green;
    font-size: 1rem;
  }
}
</style>