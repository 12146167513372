<template>
    <div class="container">
        <el-tooltip
                effect="dark"
                content="查看详情"
                placement="top">
            <div class="flex-center op-btn-wrapper">
                <i class="iconfont icon-eye-fill-2"/>
            </div>
        </el-tooltip>
    </div>
</template>

<style lang="scss" scoped>
@import "../assets/styles/variables";
@import "my-btn-style";

.op-btn-wrapper {
  background-color: $themeColor;
  font-size: 10px;
}
</style>