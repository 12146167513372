<template>
    <div class="container">
        <div class="display" v-if="!showEditorDialog">
            <div class="avatar-n-nickname">
                <div class="left-part">
                    <div class="title">场馆详情</div>
                    <div class="flex-row"><i class="iconfont icon-changguan"></i></div>
                </div>

                <div class="right-part flex-row">
                    <div class="op-bar">
                        <el-button size="default" type="primary" @click="openEditor">
                            <i class="iconfont icon-xiugai1"
                               style="font-size: 10px; padding-right: 5px"/> 编辑
                        </el-button>
                    </div>
                </div>
            </div>

            <div class="content">
                <div class="left-part">
                    <div class="logo">
                        <el-image class="img" :src="arena.logo"></el-image>
                    </div>

                    <div class="tabs">
                        <div
                                style="cursor: pointer"
                                class="tab" @click="activeTab = 'basic-info'"
                                :class="activeTab === 'basic-info' ? 'active': ''">
                            基本信息
                        </div>

                        <div
                                style="cursor: pointer"
                                class="tab" @click="activeTab = 'images'"
                                :class="activeTab === 'images' ? 'active': ''">图片集
                        </div>

                        <div class="tab" @click="activeTab = 'h5-detail'"
                             :class="activeTab === 'h5-detail' ? 'active': ''"
                             style="display: None">图文详情
                        </div>
                    </div>

                </div>

                <div class="right-part">
                    <div v-if="activeTab === 'basic-info'" class="basic-info">

                        <div class="title">{{ arena.name }}</div>

                        <div class="info">

                            <div class="row">
                                <div class="key">地址:</div>
                                <div class="val">{{ arena.addr }}</div>
                            </div>

                            <div class="row">
                                <div class="key">电话:</div>
                                <div class="val">{{ arena.tel }}</div>
                            </div>

                            <div class="row">
                                <div class="key">营业时间:</div>
                                <div class="val">{{ arena.openFromToStr }}</div>
                            </div>

                            <div class="row">
                                <div class="key">支持运动:</div>
                                <div class="val">
                                    <div v-for="(sport, idx) in arena['supportedSportObjs']" :key="idx">
                                        {{ sport.name }}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div v-if="activeTab === 'images'" class="images">
                        <div class="image-box" v-for="(image, idx) in arena.images" :key="idx">
                            <el-image :src="image" class="image" mode="fit"/>
                        </div>
                    </div>

                    <div v-if="activeTab === 'h5-detail'">
                        我是图文详情
                    </div>

                </div>
            </div>
        </div>

        <div v-else class="editor">
            <arena-detail-editor :arena="arena" @canceled="closeEditor" @saved="closeEditor"></arena-detail-editor>
        </div>

    </div>
</template>

<script>
import arenaService from '../services/arena-service'
import ArenaBasicInfo from "@/ams/components/ArenaBasicInfo"
import ArenaImage from "@/ams/components/ArenaImage"
import ArenaH5Detail from "@/ams/components/ArenaH5Detail"
import ArenaDetailEditor from "@/ams/views/ArenaDetailEditor"
import menuService from '@/ums/services/menu-service'

export default {
    name: "ArenaDetail",
    components: {ArenaH5Detail, ArenaImage, ArenaBasicInfo, ArenaDetailEditor},

    created() {
        this.reload()
    },

    data() {
        return {
            arena: {},
            activeTab: 'basic-info',
            showEditorDialog: false
        }
    },

    methods: {
        reload() {
            arenaService.fetchArenaDetailOfCurUser().then(resp => {
                this.arena = resp.data
                let [a, b] = this.arena.openFromTo // [null, null] or [9, 23]
                if (a && b) {
                    this.arena.openFromToStr = this.$fuu.toHHMM(a) + ' - ' + this.$fuu.toHHMM(b)
                } else {
                    this.arena.openFromToStr = '场馆尚未有场地'
                }
            })
        },

        openEditor() {
            menuService.changeCurTabName('编辑场馆')
            this.showEditorDialog = true
        },

        closeEditor() {
            this.showEditorDialog = false
            menuService.restoreCurTabName()
            this.reload()
        }

    }

}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables";

$leftPartWidth: 10rem;
$topHeight: 40px;

.container {
  background-color: white;
  height: calc(100vh - #{$topBarHeight});
}

.avatar-n-nickname {

  display: flex;
  flex-direction: row;
  align-items: center;

  height: $topHeight;

  font-size: $titleFontSize;
  font-weight: $titleFontWeight;
  border-bottom: 1px solid grey;

  .left-part {
    padding-left: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    width: $leftPartWidth;

    .iconfont {
      margin-left: 10px;
      font-size: calc(#{$titleFontSize} * 1.25);
    }

  }

}


.content {
  display: flex;
  flex-direction: row;

  .left-part {
    width: $leftPartWidth;
    border-right: 1px solid grey;

    .logo {
      width: $leftPartWidth;
      height: $leftPartWidth;
      padding: 20px;

      .img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }

    .tabs {

      .tab {
        display: flex;
        // justify-content: center;
        align-items: center;
        width: calc(0.8 * #{$leftPartWidth});
        margin-left: 20px;
        height: 40px;
        font-size: $titleFontSize;
      }

      .tab.active {
        background-color: #f0f5fe;
        color: #005bf5;
        font-weight: $titleFontWeight;
        border-radius: 5px;
      }

    }
  }

  .right-part {
    width: 100%;

    .basic-info {
      padding-left: 10px;
      margin-top: 10px;

      .title {
        font-weight: 800;
        font-size: $titleFontSize;
        margin-bottom: 10px;
      }

      .info {
        .row {
          display: flex;
          flex-direction: row;

          .key {
            width: 60px;
            text-align: right;
            margin-right: 10px;
          }

          height: 25px;
        }
      }


    }

    .images {
      padding-left: 20px;

      .image-box {
        width: 800px;
        padding: 10px;

        .image {
          width: 100%;
          border-radius: 15px;
        }
      }
    }
  }

}

</style>