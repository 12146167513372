import userService from "@/ums/services/user-service"
import router from "@/router"
import menuService from "@/ums/services/menu-service"
import store from "@/store"

router.beforeEach((to, from, next) => {

    console.log('to: ' + to.path)

    let whiteList = new Set(['/login', '/logout'])
    if (whiteList.has(to.path)) {
        console.log(`${to.path} in white list, let go.`)
        next()
        return
    }

    ensureUserInfoAndMenus().then(_ok => {
        ensureTabOpen(to)
        next() // can access
    }).catch(err => {
        console.log(err)
        router.push('/login').then(_ => null) // TODO: add redirect
    })
})


function ensureUserInfoAndMenus() {
    return new Promise((resolve, reject) => {
        let loggedIn = userService.loggedIn()
        if (!loggedIn) {
            reject("Didn't log in")
            return
        }

        let p1 = userService.ensureUserInfo()
        let p2 = menuService.ensureMenus()

        Promise.all([p1, p2]).then(_ => {
            console.log('Got user info and menus.')
            resolve([store.state.user.info, store.state.menu.menus])
        }).catch(err => reject(err))

    })
}

function ensureTabOpen(to) {
    let menu = store.state.menu.path2Menu[to.path]
    if (menu) {
        menu.__fullPath = to.fullPath
        menuService.openTab(menu, 0)
    }
}