<template>
    <div class="container">
        <el-tooltip
                effect="dark"
                content="删除"
                placement="top">
            <div class="flex-center op-btn-wrapper">
                <i class="iconfont icon-shanchu"/>
            </div>
        </el-tooltip>
    </div>
</template>

<style lang="scss" scoped>
@import "my-btn-style";

.op-btn-wrapper {
  background-color: red;
}
</style>