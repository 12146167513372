<template>
  <div class="role-menu-container">

    <div class="lock">
      <i class="iconfont" style="font-size: 25px; border: 1px solid green"
         :class="canEdit ? 'icon-unlock-fill': 'icon-lock-fill'" @click="canEdit = !canEdit"/>
    </div>

    <div class="container-content">

      <div class="left">
        <div class="menu" :key="menu._id" v-for="menu in menus"
             :class="currentMenu === menu ? 'active': ''" @click="currentMenu=menu">
          <el-checkbox :model-value="containsChdFunction(menu)[0]" :indeterminate="containsChdFunction(menu)[2]"
                       :readonly="!canEdit" @change="checked => checkedMenu(checked, menu)"/>
          {{ menu.name }}
        </div>
      </div>

      <div class="right-part">
        <div v-for="subMenu in subMenus" class="sub-menu-block" :key="subMenu.id">
          <div class="category">{{ subMenu.name }}</div>
          <div class="category-content">
            <div class="menu-and-items" v-for="menuAndItems in subMenu.children">
              <div class="menu flex-center">
                <el-checkbox :model-value="containsChdFunction(menuAndItems)[0]"
                             :indeterminate="containsChdFunction(menuAndItems)[2]" :readonly="!canEdit"
                             @change="checked => checkedMenu(checked, menuAndItems)"/>
                {{ menuAndItems.name }}
              </div>
              <div class="items">
                <div class="item" v-for="item in menuAndItems.children">
                  <el-checkbox :model-value="checkedMenuIds.has(item._id)" :readonly="!canEdit"
                               @change="checked => checkedMenu(checked, item)"/>
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>

    <div v-if="canEdit">
      <el-button type="primary" @click="updateRoleMenus">保存</el-button>
    </div>

  </div>


</template>

<script>

export default {
  name: "RoleMenu",
  props: ['role'], // TODO: role is an object

  created() {
    this.reload()
  },

  computed: {
    subMenus() {
      if (this.currentMenu && this.currentMenu.children) return this.currentMenu.children
      return []
    }
  },

  data() {

    return {
      canEdit: false,
      currentMenu: null,
      menus: [],

      checkedMenuIds: new Set()
    }
  },


  methods: {

    reload(activeSubMenuIdx = 0) {
      this.$http.get('/ums/menus')
          .then(resp => {
            this.menus = resp.data.data
            this.currentMenu = this.menus[activeSubMenuIdx]
          })
    },

    checkedMenu(checked, menu) {
      let leafMenuIds = findAllLeafMenuIds(menu)
      if (checked) {
        for (let menuId of leafMenuIds) this.checkedMenuIds.add(menuId)
      } else {
        for (let menuId of leafMenuIds) this.checkedMenuIds.delete(menuId)
      }
    },

    containsChdFunction(menu) {

      let leafMenuIds = findAllLeafMenuIds(menu)
      let containsAny = false, containsAll = true

      for (let menuId of leafMenuIds) {
        if (this.checkedMenuIds.has(menuId)) {
          containsAny = true
        } else {
          containsAll = false
        }
      }

      let intermediate = containsAny && !containsAll
      return [containsAny, containsAll, intermediate]
    },

    updateRoleMenus() {
      /*let req = {
        'roleKey': this.role.roleKey,
        'menus': [...this.checkedMenuIds]
      }*/

      this.$http.put(`/ums/roles/${this.role._id}/menus`, [...this.checkedMenuIds])
          .then(resp => {
            this.$fuu.promptingCheckSuccess(resp)
          }).catch(_err => this.$message.error('操作失败'))
    }

  }
}

function findAllLeafMenuIds(menu, menuIdSet = null) {
  if (menuIdSet === null) menuIdSet = new Set()

  if (!menu.children) {
    menuIdSet.add(menu._id)
  } else {
    for (let chd of menu.children) {
      findAllLeafMenuIds(chd, menuIdSet)
    }
  }

  return menuIdSet
}


</script>

<style scoped lang="scss">

.role-menu-container {
  display: flex;
  flex-direction: column;
}

.container-content {

  display: flex;
  border: 1px solid green;
  width: 100%;

  .left {
    border: 1px solid red;
    width: 150px;

    .menu {
      background-color: #f2f2f2;
      height: 40px;
      border: 1px solid hotpink;
      display: flex;
      padding-left: 20px;
      align-items: center;
      font-weight: 600;
    }

    .menu.active {
      background-color: white;
    }
  }

  .right-part {
    border: 1px solid green;
    flex: 1;

    .sub-menu-block {
      padding-left: 10px;
      display: flex;
      flex-direction: column;

      .category {
        border: 1px solid cyan;
        width: 100%;
        height: 40px;
        font-weight: 550;
        display: flex;
        align-items: center;
        background-color: white;
      }

      .category-content {
        border: 1px solid red;
        width: 100%;

        .menu-and-items {
          display: flex;
          flex-direction: row;
          align-items: center;
          min-height: 40px;
          border: 1px solid red;

          .menu {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: hotpink;
            width: 100px;
            border: 1px solid black;
          }

          .items {
            display: flex;
            flex-direction: row;

            .item {
              padding-right: 10px;
            }
          }
        }
      }
    }


  }
}
</style>
