import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from "element-plus"
import "element-plus/dist/index.css"

import http from "./utils/request"

import "./assets/iconfont/iconfont.css"
import fuu from '@/utils/frequently-used-utils'

import locale from 'element-plus/lib/locale/lang/zh-cn'
import '@/commons/access-control'


import '@/assets/rem'
import '@/assets/styles/global-style.scss'

const app = createApp(App)

app.config.globalProperties.$http = http
app.config.globalProperties.$fuu = fuu
app.config.globalProperties.$store = store


app.use(store)
    .use(router)
    .use(ElementPlus, {locale})
    .mount('#app')



