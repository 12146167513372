<template>
    <div @contextmenu="onContextMenu($event)">
        Right click to show contextmenu !
    </div>

  <!--this is component mode of context-menu-->
    <context-menu
            v-model:show="show"
            :options="optionsComponent"
    >
        <context-menu-item label="Simple item" @click="alertContextMenuItemClicked('Item1')"/>
        <context-menu-item label="Item with a icon" icon="icon-correct-2"
                           @click="alertContextMenuItemClicked('Item2')"/>
        <context-menu-separator/>
        <context-menu-group label="Menu with child">
            <context-menu-item label="Item1" @click="alertContextMenuItemClicked('Item2-1')"/>
            <context-menu-item label="Item1" @click="alertContextMenuItemClicked('Item2-2')"/>
            <context-menu-group label="Child with v-for 50">
                <context-menu-item v-for="index of 50" :key="index" :label="'Item3-'+index"
                                   @click="alertContextMenuItemClicked('Item3-' + index)"/>
            </context-menu-group>
        </context-menu-group>
    </context-menu>
</template>

<script>
import {defineComponent} from 'vue'
import {ContextMenu, ContextMenuGroup, ContextMenuSeparator, ContextMenuItem} from '@imengyu/vue3-context-menu';

export default defineComponent({
    data() {
        return {
            show: false,
            optionsComponent: {
                iconFontClass: 'iconfont',
                customClass: "class-a",
                zIndex: 3,
                minWidth: 230,
                x: 500,
                y: 200
            }
        }
    },
    components: {
        ContextMenu,
        ContextMenuGroup,
        ContextMenuSeparator,
        ContextMenuItem,
    },

    methods: {
        onContextMenu(e) {
            e.preventDefault();
            //Set the mouse position
            this.optionsComponent.x = e.x;
            this.optionsComponent.y = e.y;
            //Show menu
            this.show = true;
        },
        alertContextMenuItemClicked(name) {
            alert('You clicked ' + name + ' !');
        },
    }

});
</script>
