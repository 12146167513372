<template>
    <div class="region-detail-container">
        <div class="left">
            <div class="tab" @click="tabClicked('courts')"
                 :class="activeTab === 'courts' ? 'active': ''">
                场地
            </div>

            <div class="tab" @click="tabClicked('reservation-info')"
                 :class="activeTab === 'reservation-info' ? 'active': ''">
                预定详情
            </div>
        </div>

        <div class="right">
            <div v-if="activeTab === 'courts'">
                <court :region-id="regionId"/>
            </div>

            <div v-if="activeTab === 'reservation-info'">
                <crm2 :region-id="regionId"/>
            </div>
        </div>

    </div>
</template>

<script>
import Court from "@/ams/components/Court"
import CRM2 from "@/ams/components/CRM2"

export default {
    name: "RegionDetail",
    components: {Court, 'crm2': CRM2},

    beforeMount() {
        let params = this.$fuu.curUrlParams()
        this.regionId = params._id
        this.activeTab = params.tab || 'reservation-info'
    },

    data() {
        return {
            regionId: undefined,
            activeTab: 'reservation-info',
        }
    },

    methods: {

        tabClicked(tabName) {
            this.activeTab = tabName
        }

    }
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables";
@import "../styles/variables";

.region-detail-container {
  display: flex;
  flex-direction: row;
  padding-top: $regionDetailTopPadding;

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: $regionDetailLeftTabsWith;

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 550;

      width: 35px;
      border: 1px solid $themeColor;
      writing-mode: tb;
      height: 80px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-right: 2px solid $themeColor;

    }

    .tab.active {
      background-color: $themeColor;
      color: white;
      font-weight: 600;
      width: 36.5px;
    }

  }

  .right {
    width: calc(#{$contentWidth} - 2 * #{$contentLeftRightPadding});
    padding-left: 5px;
    padding-right: 5px;
  }

}
</style>