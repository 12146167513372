<template>
    <div class="button-container">
        <el-button type="primary" :size="size">
            <i class="iconfont icon-add-2" style="margin-right: 2px"></i>
            {{ content }}
        </el-button>
    </div>
</template>

<script>
export default {
    name: "ButtonAdd",
    props: {
        content: {
            type: String,
            default: '添加'
        },

        size: {
            type: String,
            default: 'default'
        }

    }
}
</script>

<style scoped>
.button-container {
    padding-right: 5px;
}
</style>