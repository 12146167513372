import httpClient from "@/utils/request"
import fuu from "@/utils/frequently-used-utils"
import dayjs from "dayjs";

function fetchDailyOrdersChart() {
    return new Promise(resolve => {
        httpClient.get('/home/daily-orders-chart').then(resp => {
            let opt = buildDailyOrdersChartOpt(resp)
            resolve(opt)
        })
    })
}

/**
 * @param resp see:
 */
function buildDailyOrdersChartOpt(resp) {
    window.R = resp

    let recs = resp.data

    let xArr = []
    let yArr = []

    for (let rec of recs) {
        xArr.push(rec.date)
        yArr.push(rec.count)
    }

    let opt = fuu.copy(DAILY_ORDERS_CHART_OPT_DEMO)
    opt.xAxis.data = xArr
    opt.series[0].data = yArr

    return opt
}


const DAILY_ORDERS_CHART_OPT_DEMO = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#6a7985'
            }
        }
    },
    xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
        type: 'value',
    },
    title: [
        {
            left: 'center',
            text: '每日订单数'
        },
    ],
    series: [
        {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true
        }
    ]
}


function fetchOccupancyRateCharts() {
    return new Promise(resolve => {
        let query = {'localDate': dayjs().format('YYYY-MM-DD')}
        httpClient.get('/home/occupancy-rate-charts', query).then(resp => {
            let builder = new OccupancyRateChartsBuilder(resp)
            let echartOpts = builder.build()
            resolve(echartOpts)
        })
    })
}

class OccupancyRateChartsBuilder {
    constructor(resp) {
        this.resp = resp
        this.echartOpts = []
    }

    build() {
        for (let region of this.resp.data) {
            this.buildOneRegionEchart(region)
        }
        return this.echartOpts
    }

    buildOneRegionEchart(region) {
        let opt = fuu.copy(OCCUPANCY_PIE_CHART_DEMO)
        opt.title[0].text = region.name + '满场率'
        opt.series[0].data[0].value = fuu.floatWithPrecision(region['occupancyRate'] * 100, 0)
        this.echartOpts.push(opt)
    }

}

const OCCUPANCY_PIE_CHART_DEMO = {
    title: [
        {
            left: 'center',
            text: '每日订单数'
        },
    ],

    series: [
        {
            type: 'gauge',
            axisLine: {
                lineStyle: {
                    width: 30,
                    color: [
                        [0.3, '#fd666d'], // fd666d
                        [0.7, '#37a2da'],
                        [1, '#67e0e3']
                    ]
                }
            },
            pointer: {
                itemStyle: {
                    color: 'inherit'
                }
            },
            axisTick: {
                distance: -30,
                length: 8,
                lineStyle: {
                    color: '#fff',
                    width: 2
                }
            },
            splitLine: {
                distance: -30,
                length: 30,
                lineStyle: {
                    color: '#fff',
                    width: 3
                }
            },
            axisLabel: {
                color: 'inherit',
                distance: 40,
                fontSize: 20
            },
            detail: {
                valueAnimation: true,
                formatter: '{value} %',
                color: 'inherit'
            },
            data: [
                {
                    value: 70
                }
            ]
        }
    ]
}

export default {
    fetchDailyOrdersChart,
    fetchOccupancyRateCharts
}