<template>
    <div class="container">

        <div class="fixed">
            <div class="logo">
                <img :src="logoImgB64Def" fit="fit"/>
            </div>

            <div class="menus">
                <div
                        v-for="menu in menus" :key="menu._id"
                        class="menu menu-bar-menu link"
                        :class="menu._id === activeMenu._id ? 'active': ''"
                        @click="onMenuClick(menu)"
                        @mouseover="mouseOverMenu = menu"
                >
                    <i class="icon iconfont" :class="menu.icon ? `icon-${menu.icon}`: ''"></i>
                    <span>{{ menu.name }}</span>
                </div>
            </div>
        </div>

        <div class="sub-menus" :class="expanded? 'expanded': ''">
            <menu-bar-sub-menu-block
                    @clicked="expanded = false" v-if="expanded"
                    v-for="blockMenu in subMenuBlocks"
                    :menu="blockMenu" :key="blockMenu._id"
            />
        </div>

    </div>
</template>

<script>
// string. e.g. data:image/png;base  some base64
import logoImgB64Def from "../../../assets/logo.png"
import menuService from "../../../ums/services/menu-service"
import MenuBarSubMenuBlock from "./MenuBarSubMenuBlock"
import {mapState} from "vuex"


function clickedMenu(event) {

    let target = event.target
    while (true) {

        if (target.attributes) {
            let clazz = target.attributes['class']
            if (clazz && clazz.value.includes('menu-bar-menu')) return true
        }

        target = target.parentElement

        if (!target) break
    }
    return false
}

export default {
    name: "MenuBar",
    components: {MenuBarSubMenuBlock},

    created() {
        menuService.ensureMenus()

        document.addEventListener('click', e => {
            if (!clickedMenu(e)) {
                this.expanded = false
            }
        })

    },

    data() {
        return {
            expanded: false,
            logoImgB64Def,
            mouseOverMenu: null,
        }
    },

    computed: {
        ...mapState('menu', {
            menus: state => state.menus,
            activeMenu: state => state.activeMenu
        }),

        subMenuBlocks() {
            if (this.mouseOverMenu) {
                return this.mouseOverMenu.children
            } else {
                return this.activeMenu.children
            }
        }

    },

    watch: {
        expanded(v) {
            if (!v) {
                menuService.correctActiveRootMenu()
            }
        }
    },

    methods: {
        onMenuClick(menu) {
            this.mouseOverMenu = menu
            this.expanded = !this.expanded
        }
    }

}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/variables";
@import "../../../assets/iconfont/iconfont.css";

.container {
  display: flex;
  width: 100%;

  .fixed {
    width: 100%;
    background-color: $menuBarColor;
    height: 100vh;

    .logo {
      width: $menuBarWidth;
      height: $menuBarWidth;
      display: flex;
      padding: 5px;

      img {
        flex: 1;
      }
    }

    .menu {
      height: 56px;
      color: #939598; // TODO: use a variable
      font-size: $menuBarFontSize; // TODO: use a variable
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .iconfont {
        width: 25px;
        height: 25px;
        font-size: calc(#{$menuBarFontSize} * 1.25);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .menu.active {
      color: #ffffff;
      background-color: #43464c;
    }

    .menu:hover {
      color: white;
    }
  }

  .sub-menus {
    position: fixed;
    left: $menuBarWidth;
    height: 100%;
    transition: width 0.1s;
    background-color: white;
    z-index: 1024;

    display: None;
  }

  .sub-menus.expanded {
    display: block;
    width: $subMenuBlockWidth;
    height: 100%;
  }

}
</style>
