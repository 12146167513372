<template>
    <div class="top-bar-tab-wrapper" :class="isActive ? 'active': ''" @click="onTabClick">
        <div class="content flex-row" @contextmenu="onCtxMenu($event)">
            <span class="text">
                {{ props.tab.name }}
            </span>

            <span class="close-btn" v-if="props.tab._id !== 0">
                <i class="iconfont icon-guanbi1" @click.stop="closeCurTab"/>
            </span>
        </div>

        <div class="context-menu-wrapper">
            <context-menu
                    v-model:show="showCtxMenu"
                    :options="ctxMenuOpts">

                <context-menu-item
                        v-if="canClose"
                        label="关闭当前页面"
                        @click="closeCurTab"/>

                <context-menu-item
                        v-if="hasLeft"
                        label="关闭左侧页面"
                        @click="closeTabsToLeft"/>

                <context-menu-item
                        v-if="hasRight"
                        label="关闭右侧页面"
                        @click="closeTabsToRight"/>

                <context-menu-item
                        label="关闭其他页面"
                        @click="closeOtherTabs"/>

            </context-menu>
        </div>

    </div>
</template>

<script setup>
import {ContextMenuItem, ContextMenu} from "@imengyu/vue3-context-menu"
import {computed, reactive, ref} from "vue"
import menuService from "@/ums/services/menu-service"
import store from "@/store"

const props = defineProps({
    tab: {
        type: Object,
    }
})

const isActive = computed(_ => {
    return store.state.menu.activeTab._id === props.tab._id
})

const canClose = computed(_ => {
    return props.tab._id !== 0
})

const hasLeft = computed(_ => {
    let idx = store.state.menu.selectedTabs.findIndex(t => t._id === props.tab._id)
    if (idx < 0) return false

    return idx >= 2 // if 1, left is home page, no left
})

const hasRight = computed(_ => {
    let idx = store.state.menu.selectedTabs.findIndex(t => t._id === props.tab._id)
    if (idx < 0) return false

    let lastIdx = store.state.menu.selectedTabs.length - 1
    return idx < lastIdx
})

const showCtxMenu = ref(false)
const ctxMenuOpts = reactive({
    iconFontClass: 'iconfont',
    customClass: 'my-menu',
    theme: 'flat',
    zIndex: 1024,
    x: 500,
    y: 200
})

function onTabClick() {
    console.log('Opening tab:', props.tab)
    menuService.switchTab(props.tab)
}

function onCtxMenu(e) {
    e.preventDefault()

    //Set the mouse position
    ctxMenuOpts.x = e.x
    ctxMenuOpts.y = e.y

    showCtxMenu.value = true
}

function closeCurTab() {
    menuService.closeTab(props.tab)
    // showCtxMenu.value = false
}

function closeTabsToLeft() {
    menuService.closeTabsToLeft(props.tab)
}

function closeTabsToRight() {
    menuService.closeTabsToRight(props.tab)
}

function closeOtherTabs() {
    menuService.closeTabsExcept(props.tab)
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/variables";

.top-bar-tab-wrapper {

  margin-top: calc(#{$topBarHeight} * 0.1);
  height: calc(#{$topBarHeight} * 0.9);
  padding-left: 15px;
  padding-right: 15px;

  display: flex;
  align-items: center;
  cursor: pointer;

  .content {
    justify-content: space-around;

    .text {
    }

    .close-btn {
      margin-left: 13px;
      color: $topBarColor;
      cursor: pointer;
    }
  }
}

.top-bar-tab-wrapper:hover {
  .close-btn {
    color: white;
  }
}

.top-bar-tab-wrapper.active {

  background-color: white;
  color: black;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .close-btn {
    display: block;
    color: black;
  }
}

</style>