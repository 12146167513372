<template>
  <div class="container">
    <div class="left">
      <div v-for="role in items" :key="role._id" @click="activeRoleId = role._id">{{ role.name }}</div>
      <div>
        <el-button type="primary" @click="showUpsertDialog = 1" size="small">添加角色</el-button>
      </div>
    </div>

    <div class="right-part" v-if="activeRole">
      <div class="title">{{ activeRole.name }}</div>
      <el-tabs v-model="activeTab" class="tabs">
        <el-tab-pane label="用户" name="role-users">
          <role-user :role="activeRole"/>
        </el-tab-pane>
        <el-tab-pane label="功能权限" name="role-menus">
          <role-menu :role="activeRole"/>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog :model-value="showUpsertDialog > 0" @close="showUpsertDialog = 0" style="width: 200px">
      <el-form ref="form" :model="form" :rules="rules" size="small" label-width="125px">

        <el-form-item label="角色名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入角色名称" clearable :style="{width: '100%'}"></el-input>
        </el-form-item>

        <el-form-item label="角色KEY" prop="roleKey">
          <el-input v-model="form.roleKey" placeholder="如: admin" clearable :style="{width: '100%'}"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="submitForm">提交</el-button>
          <el-button size="small" @click="$refs.form.resetFields()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import roleService from '../services/role-service'
import RoleMenu from '../components/RoleMenu'
import RoleUser from "@/ums/components/RoleUser"; // seems like menu will conflict with sth

export default {
  name: "Role",

  components: {
    RoleUser,
    RoleMenu
  },

  created() {
    this.reload()
  },

  data() {
    return {
      loading: false,
      items: [],
      activeRoleId: null,

      showUpsertDialog: 0,

      form: {
        name: undefined,
        roleKey: undefined,
      },

      rules: {
        name: [{
          required: true,
          message: '请输入角色名称',
          trigger: 'blur'
        }],
        roleKey: [{
          required: true,
          message: '如: admin',
          trigger: 'blur'
        }],
      },

      activeTab: undefined

    }
  },

  computed: {
    activeRole() {
      let arr = this.items.filter(r => r._id === this.activeRoleId)
      if (arr) return arr[0]
      else return {}
    }
  },

  watch: {
    activeRoleId(id2, _id1) {
      console.log('Changed!')
    }
  },

  methods: {

    reload() {
      this.$fuu.loadItems(this, roleService.fetchMany)
          .then(items => {
            if (!this.activeRoleId) {
              this.activeRoleId = items[0]._id
            }
          })
    },

    submitForm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return

        this.$http.post('/ums/roles', this.form)
            .then(resp => {
              let ok = this.$fuu.promptingCheckSuccess(resp)
              if (ok) this.reload()
              this.showUpsertDialog = 0
            })
            .catch(_err => {
              this.$message.error('操作失败!')
              this.showUpsertDialog = 0
            })
      })

    }

  }

}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: row;
  width: 100%;

  .left {
    width: 150px;
    background-color: hotpink;
  }

  .right-part {
    flex: 1;
  }

}
</style>
